.container {
  color: #4a5568;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  padding: 1em;
  overflow-y: auto;

  .header {
    margin-bottom: 1em;
    display: flex;

    .title {
      font-weight: 700;
      font-size: 1.875rem;
      flex-grow: 1;
    }
  }
}

.TableElement {
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}