@import '../../../../assets/styles/variables.scss';

.propertiesCellHeader{
  background-color: #F3F3F5;
  width: 30px ;
  height: 40px;
  padding: 0%;
  border: none;
}

.propertyColumnTypography{
  width: 30px;
  padding: 0%;
  margin: 0%;
}

.headerCell{
  background-color: #F3F3F5;
  padding: 0%;
  border: none;
  height: 40px;
}

.headerTypography{
  color: #232E39;
  font-family: "quicksand";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
  padding: 0%;
}