.container {
  color: #000;
  // .font-sans
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  // .antialiased
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // base
  font-size: 14px;

  // .shadow
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  // .p-6
  padding: 1em;
  //.mb-4 	
  margin-bottom: 1em;
  // .rounded
  border-radius: 0.25rem;
  
  .header {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 1.25em;
  }
  .primes {
    display: flex;
    font-size: 16px;
    .prime {
      display: flex;
      margin-right: 15px;
      .title {
        margin-right: 15px;
      }
      .value {
      }
    }
  }
}
