@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/fonts.module.scss';

$table-border: 0.0625rem solid $gray-300;

.root {
  &:first-child {
    /* The first banned head cell */
    [class~='MuiTableRow-head']:last-child {
      [class~='MuiTableCell-root']:first-child {
        /* Visual */
        border-top: $table-border;
        border-right: $table-border;
      }

      [class~='MuiTableCell-root']:nth-child(2) {
        /* Box-model */
        padding-left: 1rem;
      }
    }

    /* The first body cell of each row */
    tbody [class~='MuiTableCell-root']:first-child {
      /* Visual */
      border-right: $table-border;
    }
  }

  /* Banned head cells */
  [class~='MuiTableRow-head']:last-child [class~='MuiTableCell-root'] {
    /* Box-model */
    padding: 0.625rem 0.75rem;

    /* Visual */
    border-right: none;

    &:first-child {
      /* Box-model */
      padding-left: 1rem;

      /* Visual */
      border-left: $table-border;
    }

    &:last-child {
      /* Visual */
      border-right: $table-border;
    }
  }

  tbody {
    [class~='MuiTableCell-root'] {
      /* Typography */
      color: $gray-700;
      font-family: $subtitle1-font-family;
      font-size: $subtitle1-font-size;
      font-style: $subtitle1-font-style;
      font-weight: $subtitle1-font-weight;
      letter-spacing: $subtitle1-letter-spacing;
      line-height: $subtitle1-line-height;

      &:first-child {
        /* Visual */
        border-left: $table-border;
      }

      &:last-child {
        /* Visual */
        border-right: $table-border;
      }
    }

    /* Last row */
    tr:last-child [class~='MuiTableCell-root'] {
      /* Typography */
      color: $gray-900;

      /* Visual */
      background-color: transparent;
      border: none;

      &:first-child {
        /* Visual */
        border-right: none;
      }
    }
  }
}
