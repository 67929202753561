@import '../../assets/styles/variables.scss';

$width: 25px;
$height: 25px;
$border-radius: 5px;

.container {
  .fabContainer {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
  }
  .actionContainer {
    margin-top: 15px;
    .button {
      width: 100%;
    }
  }
  .tableContainer {
    overflow: auto;
    max-height: 77vh;
  }
}

.tableRoot {
  width: 100% !important;
}

.status {
  width: 20% !important;
}

.client {
  width: 23% !important;
}

.Label {
  font-size: 16px;
  margin-right: 14px;
}

.GreenHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-green-color;
  border-radius: $border-radius;
}
.YellowHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $secondary-color;
  border-radius: $border-radius;
}
.BlueHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-color;
  border-radius: $border-radius;
}

.ContainerSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
}
.GreenHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-green-color;
  border-radius: $border-radius;
}
.YellowHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $secondary-color;
  border-radius: $border-radius;
}
.BlueHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-color;
  border-radius: $border-radius;
}
