@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.headerCell {
  height: 40px !important;
  border-top: solid 1px $gray-300;
  border-bottom: solid 1px $gray-300;
  background-color: $primary-100;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0%;
  margin: 0%;
}

.headerCell:first-child {
  border-left: solid 1px $gray-300;
  padding-left: 70px;
  height: 40px !important;
}

.headerCell:last-child {
  border-right: solid 1px $gray-300;
  height: 40px !important;
}

.headerTypography {
  display: flex;
  align-items: center;
  height: 40px;
  width: 108px;
  color: $gray-700;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0%;
  height: 40px !important;
  margin-left: 10px;
}

.leftLine {
  border-left: solid 1px $primary-600;
  padding-left: 2px;
}

.errorLeftLine {
  border-left: solid 1px $state-error-700;
}
 
.rightLine {
  border-right: solid 1px $primary-600;
}

.errorRightLine {
  border-right: solid 1px $state-error-700;
}
