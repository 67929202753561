@import '../../../assets/styles/settings/colors.module.scss';

.scrollBar {
  overflow-y: auto;
  display: flex;
  flex: 1 1 100px;
  flex-direction: column;
  min-height: 0;
  width: 100%;
  &::-webkit-scrollbar {
    width: .5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.4);
    border-radius: 8;
  }
}

.menuItem {
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-200;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-200 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-200 !important;
}