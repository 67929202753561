@import '../../../../assets/styles/variables.scss';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1%;

  .buttonNormal {
    color: $main-color;
    border: solid .5px $main-color !important;
    background-color: #fff;
  }

  .buttonSelected {
    color: #fff;
    background-color: $main-color;
  }
}
