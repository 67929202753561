@import '../../../assets/styles/settings/colors.module.scss';

.FormMainView_content {
  padding: 0;
}

.FormMainView_closeIcon {
  position: absolute;
  right: 1rem;
}

.TextFieldDisabled {
  background-color: $gray-200;
}

.switch {
  border-radius: 18px;
  color: $gray-600;
  width: 391px;
  background-color: $gray-200;
  border: none;
  
  & > label {
    line-height: 40px;
    &:before {
      border-radius: 17px;
      color: $primary-600;
    }
  }
}

.BoxTitle {
  background-color: $primary-100;
  color: $primary-800;
  border-radius: 4px;
}

.outlineNone {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.autocompleteOption {
  min-height: auto;
  align-items: flex-start;
}

.autocompleteOptionText {
  flex-grow: 1;
}

.noPadding {
  padding: 0;
}