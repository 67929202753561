.container {
  color: #4a5568;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  padding: 1em;
  overflow-y: auto; }
  .container .header {
    margin-bottom: 1em;
    display: flex; }
    .container .header .title {
      font-weight: 700;
      font-size: 1.875rem; }
  .container .section {
    font-size: 1.125rem; }
    .container .section .percentage {
      font-size: 1.5rem;
      font-weight: 700; }
    .container .section .alert {
      color: #f56565 !important; }
  .container .SelectorCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
  .container .Selector {
    width: 80%; }
