.Container {
  margin-left: 24px;
  margin-top: 16px;
}

.ContainerDetail {
  margin-left: 8px;
  margin-top: 8px;
}

.LeftTypography {
  width: 95px;
  margin-right: 14px;
  display: inline-block;
}

.TypographyContainer {
  margin-bottom: 8px;
}
