@import '../../../assets/styles/settings/colors.module.scss';

.BoxTitle {
  background-color: $primary-300 !important;
  color: $primary-800;
  border-radius: 4px;
}

.outlineNone {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}