@import "../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../assets/styles/settings/fonts.module.scss";
@import "../../../../../assets/styles/components/modal.module.scss";

$option-gutter: $modal-gutter;

.root {
  /* Positioning */
  position: relative;

  /* Box-model */
  display: flex;
  padding: 0 $option-gutter 0 #{$option-gutter + 0.6875rem};
  height: 44px;

  /* Flexbox Container */
  align-items: center;

  &::before,
  &::after {
    /* Positioning */
    position: absolute;
    left: $option-gutter;

    /* Box-model */
    content: "";
    width: calc(100% - #{2 * $option-gutter});
    height: 1px;

    /* Visual */
    background-color: $gray-300;
  }

  &::before {
    /* Positioning */
    top: 0;

    /* Box-model */
    content: none;
  }

  &::after {
    /* Positioning */
    bottom: 0;
  }
  
  &:hover {
    .dragIcon {
      /* Box-model */
      display: block;
    }

    &::before,
    &::after {
      /* Positioning */
      left: 0;

      /* Box-model */
      content: "";
      width: 100%;
    }
  }

  &.isGrabbing {
    /* Visual */
    background-color: $gray-100;
    outline: none;

    .dragIcon {
      /* Typography */
      color: $primary-600;
    }
  }
}

.dragIcon {
  $icon-size: 24px;

  /* Positioning */
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  
  /* Box-model */
  display: none;
  width: $icon-size;
  height: $icon-size;
  
  /* Typography */
  color: $gray-600;

  &:hover {
    /* Visual */
    cursor: grab;
  }
}

.textInput {
  /* Box-model */
  margin-left: 18px;
  padding: 0;

  /* Flexbox Item */
  flex: auto;

  /* Typography */
  color: $gray-800;
  font-family: $body1-font-family;
  font-size: $body1-font-size;
  font-style: $body1-font-style;
  font-weight: $body1-font-weight;
  letter-spacing: $body1-letter-spacing;
  line-height: $body1-line-height;

  /* Visual */
  appearance: none;
  background: none;
  border: none;

  &::placeholder {
    /* Typograpy */
    color: $gray-500;
  }

  &:focus {
    /* Visual */
    outline: none;
  }

  &:active {
    /* Visual */
    outline: none;
  }

  &:disabled {
    /* Visual */
    opacity: 0.4;
  }
}

.closeButton {
  /* Box-model */
  margin: 0 18px 0 16px;
}
