@import '../../../../assets/styles/settings/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.check {
  padding: 0px;
  height: 18px;
  width: 18px;
  color: $primary-600 !important;
}
