@import '../../assets/styles/variables.scss';

.container {
  // height: 100%;
  margin: 0 $margin-content 0 $margin-content;
  display: flex;
  flex-direction: column;
}
.list {
  margin-top: 25px;
  flex-grow: 1;
}
