@import '../../../assets/styles/settings/colors.module.scss';

.container {
  display: flex;
  justify-content: center;
  background-color: $gray-100;
}

.content {
  width: 78%;
}
