@import "../../../assets/styles/components/modal.module.scss";

.root {
  /* Box-model */
  display: flex;
  padding: $modal-gutter;
  
  /* Flexbox Container */
  justify-content: flex-end;

  & > * + * {
    /* Box-model */
    margin-left: 1rem;
  }
}