@import "../../../../../assets/styles/settings/colors.module.scss";

.eventsControlHeaderContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.buttonsContainer {
  width: 30%;
  display: flex; 
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cancelButton {
  margin-left: 30px;
}

.eventAlert {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 750px;
  height: 52px;
  border: 1px solid $informative-600;
  border-left: 6px solid $informative-600;
  border-radius: 4px;
}

.exclamationPointIcon {
  color: $informative-600;
}

.eventAlertTypography {
  color: $gray-800;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.closeIcon {
  color: $gray-600;
}
