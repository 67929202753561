@import '../../../../../assets/styles/variables.scss';

.priceContainer {
  display: flex;

  .container {
    border-radius: 4px;
    margin: 15px 10px;
    padding: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: $box-shadow;
    
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 26px;
      display: block;
      margin-bottom: 25px;
    }

    .content {
      flex-grow: 1;

      .stat {
        display: flex;
        .label {
          flex-grow: 1;
          font-weight: 700;
        }
      }
    }
  }
}
