$main-color: rgb(52, 52, 56);
$secondary-color: #a80072;
$base-color: #f3f3f5;
$ternary-color: #e04a65;
$main-blue-color: #29339b;
$main-green-color: #16db72;
$main-aqua-blue-color: #00a3ab;
$main-aqua-blue-dark-color: rgb(58, 116, 117);
$blue-color: #bcd2ee;
$green-color: #018e42;
$red-color: #ee2e31;
$yellow-color: #ffc857;
$orange-color: #f87060;
$purple-color: #55495a;
$softMain-color: #8a8fc8;
$softGreen-color: #74c197;
$softRed-color: #f58d8e;
$softYellow-color: #ffe1a3;
$softOrange-color: #fcb8b1;
$softPurple-color: #94849b;
$defaultWhite-color: #fafaff;
$white-color: #fff;
$white-background: rgb(244, 245, 247);
$softGray-color: #f4f4ff;
$softGrayBackground-color: #d3d3db;
$gray-color: rgb(162, 162, 180);
$defaultPlaceholder-color: #bdbdc5;
$grayBlack-color: rgb(79, 79, 87);
$darkGray-color: #7D848B;
$lightGrey-color: #6985a1;
$darkPurple-color: #A057B3;
$black-color: rgb(36, 36, 39);
$blackBorder-color: rgb(23, 43, 77);
$alert-color: #faf2a1;
$box-shadow-color: rgba(82, 63, 105, 0.1);
$softVariantGrayBackground-color: #f4f9ff;
$itemNoActive: #ecf0f1;
$description-color: #434C53;
$name-color: #8E9498;
$aquamarine-background: #00A3A9;

$softGreenColor: #00c781;
$redButton-color: #ff4040;
//sizes

$margin-content: 50px;
$header-size: 20px;
$body-size: 16px;
$secondaryText-size: 14px;

$box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
  -9px -9px 16px rgba(255, 255, 255, 0.5);

:export {
  mainColor: $main-color;
  mainBlueColor: $main-blue-color;
  secondaryColor: $secondary-color;
  baseColor: $base-color;
  mainGreenColor: $main-green-color;
  mainAquaGreen: $main-aqua-blue-color;
  mainAquaGreenDarkColor: $main-aqua-blue-dark-color;
  blueColor: $blue-color;
  blueLightColor: $main-aqua-blue-color;
  softMainColor: $softMain-color;
  redColor: $red-color;
  softRedColor: $softRed-color;
  greenColor: $green-color;
  softGreenColor: $softGreen-color;
  yellowColor: $yellow-color;
  softYellowColor: $softYellow-color;
  orangeColor: $orange-color;
  softOrangeColor: $softOrange-color;
  purpleColor: $purple-color;
  softPurpleColor: $softPurple-color;
  grayColor: $gray-color;
  darkPurpleColor: $darkPurple-color;
  darkGraycolor: $darkGray-color;
  itemNoActive: $itemNoActive;
  margin-content: $margin-content;
}
