@import '../../assets/styles/variables.scss';

.container {
  margin-bottom: 14px;
  display: flex;
  flex-flow: wrap;

  .outlineButton {
    background-color: #fff;
    border: 2px solid $main-color;
    color: $main-color;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 1%;
  }

  .button {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 1%;
  }
}
