@import '../PropertyDetails.module.scss';

.PropertyFilesView {
  padding: 2%;
  border-radius: 4px;
  margin: 15px 10px;
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  flex-shrink: 0;
}

.propertyFileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
}

.title {
  font-size: 1.8rem;
}

.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputFile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: #29339b;
  display: inline-block;
  padding: 5px;
  border-radius: 20px;
}

.inputFile:focus + label,
.inputFile + label:hover {
  background-color: #1c2697;
}

.inputFile + label {
  cursor: pointer;
}

.filesContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  display: flex;
  width: 30%;
  margin: 5px;
  padding: 1%;
  background-color: rgb(241, 237, 237);
  align-content: center;
  justify-content: space-between;
  border-radius: 10px;
}

.file {
  display: flex;
}

.fileActions {
  display: flex;
  flex-direction: column;
}

.deleteIcon {
  margin: 0%;
  color: rgb(243, 57, 57);
  font-size: 1.5em;
}

.downloadIcon {
  margin: 0%;
  color: #29339b;
  font-size: 1.5em;
}
