@import '../../../assets/styles/settings/colors.module.scss';

.container {
}

.header {
  padding-top: 30px;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-300;
  display: flex;
  justify-content: space-between;
}
