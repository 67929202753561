@import '../../../../../assets/styles/settings/colors.module.scss';

.title {
  /* Box-model */
  display: flex;
}

.summary {
  /* Box-model */
  display: flex;
  margin-top: 0.5rem;
  padding: 0.75rem 0.8125rem 1.125rem 1.1875rem;

  /* Flexbox Container */
  justify-content: space-between;

  /* Visual */
  border: 0.0625rem solid $gray-300;
  border-radius: 0.25rem;
}
