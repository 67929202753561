@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/components/modal.module.scss';

.root {
  /* Box-model */
  display: flex;

  /* Flexbox Container */
  flex-direction: column;

  .titleLabel {
    /* Box-model */
    margin-bottom: 8px;
  }
}

.title {
  /* Box-model */
  display: flex;
  padding: 24px $modal-gutter 28px;

  /* Flexbox Container */
  flex-direction: column;
}
