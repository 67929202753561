@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  display: inline-flex;
  width: 18px;
  height: 18px;

  /* Flexbox Container */
  justify-content: center;
  align-items: center;

  /* Typography */
  color: $gray-800;

  /* Visual */
  border-radius: 100%;
  cursor: pointer;

  &.isDisabled {
    /* Visual */
    opacity: 0.4;
  }
}

.input {
  /* Box-model */
  display: none;
}
