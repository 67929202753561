@import '../../../assets/styles/settings/colors.module.scss';

.speedSalesAlert {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 680px;
  height: 52px;
  border: 1px solid $state-error-600;
  border-left: 6px solid $state-error-600;
  border-radius: 4px;
  margin-bottom: 5px;
}
