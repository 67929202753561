@import '../../../../assets/styles/settings/colors.module.scss';

.menuRoot {
  padding: 4px;
  margin-top: -4px;
  display: flex;
  position: absolute;
  width: auto;
  overflow: inherit;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  svg {
    color: $gray-700;
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
}

.menuList {
  padding: 0;
}

.menuCenterRight {
  width: 12px;
  height: 12px;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(-135deg);
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 0px 3px;
  right: -10px;
  border-bottom: 3px solid rgba(145, 158, 171, 0.12);
  border-left: 3px solid rgba(145, 158, 171, 0.12);
  top: 22px;
}

.menuItem {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-100;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-100 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-100;
}
