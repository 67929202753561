.schedulesContainer{
  display: flex;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 630px;
}

.title{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  font-weight: bold;
  text-shadow: -1px 2px rgb(128, 126, 126);
}

.towerName{
  margin-bottom: 20px;
  font-weight: bold;
  color: rgb(122, 123, 123);
}