@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  padding: 0.5rem;
  width: 6.0625rem;

  /* Visual */
  border: 0.0625rem solid $gray-300;
  border-radius: 0.25rem;

  &.isDisabled {
    /* Visual */
    background-color: $gray-200;

    .title {
      /* Typography */
      color: $gray-400;
    }

    .date {
      /* Typography */
      color: $gray-500;
    }
  }

  &.isHighlighted {
    /* Visual */
    background-color: $accent-600;

    .title {
      /* Typography */
      color: $gray-100;
    }

    .date {
      /* Typography */
      color: $gray-000;
    }
  }
}

h3.title {
  /* Box-model */
  margin: 0;

  /* Typography */
  color: $gray-600;
  font-weight: 400;
}

div.date {
  &.isBold {
    /* Typography */
    font-weight: 500;
  }
}
