/* LIBS */
@import "~@blueprintjs/core/src/blueprint.scss";

/* SETTINGS */
@import "./assets/styles/variables.scss";
@import "./assets/styles/settings/fonts.module.scss";

/* TOOLS */

/* GENERICS */
@import "./assets/styles/generics/fontRoboto.module.scss";

/* ELEMENTS */

html {
  /* Typography */
  font-size: 16px;
}

body {
  /* Box-model */
  margin: 0;
  padding: 0;
  height: 100vh;

  /* Typography */
  color: $black-color;
  font-family: $body-font-family;
  font-size: 1rem;
  font-weight: 300;

  /* Visual */
  background: $defaultWhite-color;
}

.MuiTypography-root {
  font-family: $body-font-family;
}

/* OBJECTS */

/* COMPONENTS */

/* UTILS */