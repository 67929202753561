@import '../../../../assets/styles/settings/colors.module.scss';

.clientModal{
  & [class*='MuiDialog-container'] > [class*='MuiPaper-root'][class*='MuiDialog-paper'] {
    border-radius: 1em;
  }
}

.title {
  padding-top: 0;
  color: $primary-600;
}

.titleIcon {
  color: $primary-600;
}

.content {
  min-height: 400px;
  overflow-y: auto;
}

.content,
.footer {
  position: relative;
}

.loader {
  border-radius: 0.1em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
