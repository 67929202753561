@import '../../../assets/styles/settings/colors.module.scss';
@import '../../../assets/styles/settings/fonts.module.scss';

.root {
  &:hover {
    [class~='MuiOutlinedInput-notchedOutline'] {
      /* Visual */
      border-color: $primary-600;
    }

    [class~='MuiInputLabel-outlined'][class~='MuiInputLabel-shrink'] {
      /* Typography */
      color: $gray-900;
    }
  }

  &:focus {
    /* Visual */
    outline: none;

    [class~='MuiOutlinedInput-notchedOutline'] {
      /* Visual */
      border-color: $gray-400;
    }
  }

  [class~='MuiInputBase-root'] {
    /* Typography */
    color: $gray-900;
    font-family: $body1-font-family;
    font-size: $body1-font-size;
    font-style: $body1-font-style;
    font-weight: $body1-font-weight;
    letter-spacing: $body1-letter-spacing;
    line-height: $body1-line-height;

    &[class~='Mui-focused'] {
      [class~='MuiOutlinedInput-notchedOutline'] {
        /* Visual */
        border-color: $primary-600;
      }
    }

    &[class~='Mui-disabled'] {
      [class~='MuiOutlinedInput-notchedOutline'] {
        /* Visual */
        border-color: $gray-400;
      }
    }

    &[class~='Mui-error'] {
      [class~='MuiOutlinedInput-notchedOutline'] {
        /* Visual */
        border-color: $state-error-600;
      }
    }
  }

  [class~='MuiInputLabel-outlined'] {
    /* Positioning */
    transform: translate(1rem, 0.875rem) scale(1);

    /* Typography */
    color: $gray-500;
    line-height: 1.25rem;

    &[class~='MuiInputLabel-marginDense'] {
      /* Positioning */
      transform: translate(1rem, 0.625rem) scale(1);
    }

    &[class~='MuiInputLabel-shrink'] {
      /* Positioning */
      transform: translate(1rem, -0.375rem) scale(0.75);

      /* Typography */
      color: $gray-600;

      &[class~='Mui-focused'] {
        /* Typography */
        color: $primary-600;
      }

      &[class~='Mui-error'] {
        /* Typography */
        color: $state-error-600;
      }
    }

    &[class~='Mui-disabled'] {
      /* Typograpy */
      background-color: $gray-200;
      color: $gray-600;
    }
  }

  [class~='MuiInputBase-input'] {
    /* Box-model */
    height: 1.25rem;

    &::placeholder {
      /* Typograpy */
      color: $gray-500;
    }

    &[class~='Mui-disabled'] {
      /* Typograpy */
      background-color: $gray-200;
      color: $gray-600;
    }
  }

  [class~='MuiOutlinedInput-input'] {
    /* Box-model */
    padding: 0.875rem 1rem;
  }

  [class~='MuiOutlinedInput-inputMarginDense'] {
    /* Box-model */
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  [class~='MuiOutlinedInput-notchedOutline'] {
    /* Visual */
    border-color: $gray-300;
  }

  [class~='MuiFormHelperText-root'] {
    /* Typograpy */
    font-family: $caption-font-family;
    font-size: $caption-font-size;
    font-style: $caption-font-style;
    font-weight: $caption-font-weight;
    letter-spacing: $caption-letter-spacing;
    line-height: $caption-line-height;

    &[class~='Mui-error'] {
      /* Typography */
      color: $state-error-600;
    }
  }

  [class~='MuiOutlinedInput-multiline'] {
    /* Box-model */
    padding: 0;
  }
}
