@import '../../../../assets/styles/settings/colors.module.scss';

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 70px;
  padding: 10px;
  background-color: $gray-100;
  border-top: 2px solid rgba(105, 133, 161, 0.24);
}
.Container {
  position: relative;
}
.Spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
