.modalContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .addNewTemplateWrapper {
    position: relative;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 30px;

    .header {
      box-sizing: border-box;
      flex-shrink: 0;
      flex-basis: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    
    .content {
      position: relative;
      box-sizing: border-box;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      max-height: 100%;
      overflow-y: hidden;

      section {
        position: relative;
        box-sizing: border-box;
        padding: 30px;
        border-right: 1px dashed lightgray;
        flex-grow: 1;
        flex-basis: 50%;
        overflow-y: auto;

        &.big {
          flex-basis: 70%;
        }

        &.small {
          flex-basis: 30%;
        }

        &:last-child {
          border-right: none;
        }

        .variableList {
          font-size: 14px;
          line-height: 20px;

          .variableName {
            color: darkgray;
  
            &::before {
              content: '{';
            }
  
            &::after {
              content: '}';
            }
          }
  
          .variableDescription {
            &::before {
              content: ' - ';
            }
  
            &::after {
              content: '.';
            }
          }
        }

        .form {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          * {
            margin-bottom: 10px;
          }
        }
      }
    }

    .footer {
      box-sizing: border-box;
      flex-shrink: 0;
      flex-basis: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 10px;

      .button {
        margin-left: 10px;
      }
    }
  }
}