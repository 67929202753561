@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/fonts.module.scss';

.root {
  /* Box-model */
  display: flex;
  min-height: 3.75rem;

  /* Flexbox Container */
  align-items: flex-start;
}

.label {
  /* Typography */
  line-height: 2.5rem;
}

.input {
  /* Box-model */
  margin-left: 0.5rem;
  width: 7.25rem;

  [class~='MuiInputBase-root'] {
    /* Box-model */
    display: relative;

    &::after {
      /* Positioining */
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);

      /* Box-model */
      content: 'meses';

      /* Typography */
      color: $gray-600;
      font-family: $body-font-family;
      font-style: italic;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25;
    }
  }

  [class~='MuiInputBase-input'] {
    /* Box-model */
    padding-right: 4.9375rem;
  }

  [class~='MuiFormHelperText-root'] {
    /* Typography */
    white-space: nowrap;
  }
}

.button {
  /* Box-model */
  margin-left: 0.5rem;
}
