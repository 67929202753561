.TextField {
  max-height: 40px;

  & input {
    min-height: 40px;
    padding: 0px 14px;
  }

  & label {
    transform: translate(14px, 12px) scale(1);
  }
}
