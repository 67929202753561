@import '../../assets/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap');

.Container {
  display: flex;
  justify-content: center;
}

.tableContainer {
  height: 300px;
}

.SoftGreenHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #00dd6d;
  border-radius: 50%;
  font-family: 'quicksand';
}

.GreenHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #377476;
  border-radius: 50%;
  font-family: 'quicksand';
}

.GrayHelper {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #d8d8d8;
  border-radius: 50%;
  font-family: 'quicksand';
}

.ownerBadge {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  background-color: #aa0073;
  border-radius: 50%;
}

.Label {
  font-size: 14px;
  margin-right: 14px;
}
