@import '../../../../../assets/styles/settings/colors.module.scss';

.QuotationConfigAlert {
  position: relative;
  min-height: 50px;
  padding: 15px;
  padding-left: 45px;
  display: flex;
  align-items: center;
  border: 1px solid $gray-300;
  border-left: 4px solid $gray-300;
  color: $gray-700;
  border-radius: 4px;
}

.success {
  border-color: $state-success-600;
}

.warning {
  border-color: $state-warning-600;
}

.info {
  border-color: $informative-600;
}

.Icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.IconWaring {
  color: $state-warning-600;
}

.IconInfo {
  color: $informative-600;
}

.IconSuccess {
  color: $state-success-600;
}
