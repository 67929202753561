@import '../../../assets/styles/variables.scss';

.HeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
}

.Search {
  border: 2px solid $softGray-color;
  width: 40%;
  border-radius: 5px;
  padding: 0.8rem 0.5rem;
  justify-self: center;

  span {
    color: $defaultPlaceholder-color;
  }

  &:hover {
    box-shadow: 2px 2px 3px $box-shadow-color;
    cursor: pointer;
  }
}

.ButtonGo {
  justify-self: end;
  align-self: flex-start;
}

.buyerContainer {
  text-align: center;
  .bought {
    color: $green-color;
  }
}

.tableContainer {
  max-height: 700px;
  overflow: auto;
}
