@import '../../../assets/styles/variables.scss';

.Container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'Intersect Headers' 'Columns Table';
  font-size: 16px;
  overflow-y: auto;
  border-radius: 5px;

  .Intersect {
    position: sticky;
    left: 0;
    top: 0;
    grid-area: Intersect;
    z-index: 99;
    background: $base-color;
    color: $main-green-color;
    padding: 0.75em 1.5em;
    font-weight: bold;
  }

  .Header {
    grid-area: Headers;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 98;
    background: $base-color;
    color: $main-color;
    text-align: center;
    font-weight: bold;
    padding-right: 10px;
    & > * {
      width: 150px;
      line-height: 50px;
    }
  }

  .Element {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .Columns {
    grid-area: Columns;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 98;
    background: $base-color;
    color: $main-color;
    padding: 0em 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    & > * {
      height: 50px;
    }
  }
  .ColumnsMinWidth {
    grid-area: Columns;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 98;
    background: $base-color;
    color: $main-color;
    padding: 0em 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    & > * {
      height: 50px;
      min-width: 150px;
      justify-content: left;
    }
  }
  .Table {
    grid-area: Table;
    flex-grow: 1;

    .Row {
      display: flex;
      width: 100%;
      text-align: center;

      &:hover {
        background: rgba(243, 243, 245, 1.5);
      }

      & > * {
        width: 150px;
        height: 50px;
      }
    }
  }
}
.wrapper1 {
  width: inherit;
  border: none 0px RED;
  overflow-x: auto;
  overflow-y: hidden;
}
.wrapper2 {
  width: inherit;
  border: none 0px RED;
  overflow-x: auto;
  overflow-y: hidden;
}

.div1 {
  width: 0px;
  height: 10px;
  overflow: auto;
}
