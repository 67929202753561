.container {
  font-size: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.content {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
