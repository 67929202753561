.container {
  display: column !important;
  flex-direction: row !important;
}

.searcher,
.percentage {
  margin-bottom: 1.5% !important;
  flex-grow: 1;
}

.buttons {
  margin-right: 2% !important;
  flex-grow: 1;
}
