@import '../../../../assets/styles/settings/colors.module.scss';

.isRed {
  /* Typography */
  color: $state-error-600;
}

.isGreen {
  /* Typography */
  color: $state-success-600;
}