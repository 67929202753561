
@import "../../../assets/styles/settings/colors.module.scss";

.projectResultStateContainer {
  min-height: 87vh;
  padding: 1%;
}

.projectResultStateTableContainer {
  max-width: 82vw;
  min-height: 90vh;
}
