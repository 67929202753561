@import "../../assets/styles/variables.scss";

.Container {
    display: flex;
}

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

:export {
    color: $main-color;
}