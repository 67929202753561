@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.leftColumnsCell {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}

.itemRow {
  display: flex;
  align-items: center;
  color: $gray-700;
  background-color: #f7f9fa;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  border-left: solid 1px $gray-300;
}

.contractRow {
  display: flex;
  align-items: center;
  color: $gray-700;
  background-color: #e1e7ec;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  border-left: solid 1px $gray-300;
}

.groupRow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-800;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  height: 50px;
  width: 100%;
  border-left: solid 1px $gray-300;
}

.utilityRow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-900;
  font-weight: bold;
  background-color: $success-200;
  font-style: normal;
  font-family: $subtitle1-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-left: solid 1px $gray-300;
  height: 42px;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-900;
  min-width: 210px;
  height: 32px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.text {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px
}
