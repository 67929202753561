.container {
  .fabContainer {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;
  }
  .actionContainer {
    margin-top: 15px;
    .button {
      width: 100%;
    }
  }
  .tableContainer {
    overflow: auto;
    max-height: 500px;
  }
}

.tableRoot {
  width: auto !important;
}
