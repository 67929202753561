@import "../../../../../assets/styles/settings/colors.module.scss";

.cutAlert {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 520px;
  height: 52px;
  border: 1px solid $informative-600;
  border-left: 6px solid $informative-600;
  border-radius: 4px;
}

.exclamationPointIcon {
  color: $informative-600;
}

.cutAlertTypography {
  color: $gray-800;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.closeIcon {
  color: $gray-600;
}
