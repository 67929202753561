@import '../../../../../../assets/styles/settings/colors.module.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  padding-left: 20px;
  transition: background 0.3s;
}

.primary {
  &:hover {
    background-color: $primary-200;
  }
}
.danger {
  &:hover {
    color: $state-error-600;
    background-color: $state-error-200;
  }
}
