.info {
  display: flex;
  margin-bottom: 16px;

  .client {
    font-weight: 700;
    font-size: 21px;
  }

  .property {
    margin-right: 0;
    margin-left: auto;

    .name {
      font-weight: 700;
      font-size: 21px;
    }
  }
}

.details {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;

  .header {
    margin-bottom: 14px;
  }
}

.pricing {
  margin: 20px 0;
  .l0 {
    display: flex;
    margin-bottom: 5px;
    .title {
    }

    .value {
      margin-right: 0;
      margin-left: auto;
    }
  }
  .trade {
    display: flex;
    margin-bottom: 5px;
    .title {
    }

    .value {
      margin-right: 0;
      margin-left: auto;
    }
  }
  .financial {
    display: flex;
    margin-bottom: 5px;
    .title {
    }

    .value {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.total {
  display: flex;

  .title {
    font-weight: 700;
  }

  .value {
    font-weight: 700;
    font-size: 21px;
    margin-right: 0;
    margin-left: auto;
  }
}
