@import '../../../../assets/styles/settings/colors.module.scss';

.singleContainer {
  background: $gray-000;
  flex: 1;
}

.specialContainer {
  display: flex;
  justify-content: center;
  padding-top: 31px;
}

.specialPaper {
  display: flex;
  background: $gray-000;
  width: 70%;
}
