@import "../../../assets/styles/settings/colors.module.scss";

$dialog-width: 620px;

.root {
  /* Positioning */
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  /* Box-model */
  display: flex;
  overflow-y: auto;
  width: $dialog-width;
  max-width: $dialog-width;
  max-height: calc(100% - 64px);

  /* Flex Container */
  flex-direction: column;

  /* Visual */
  background-color: $gray-000;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);

  /* Misc */
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus {
    /* Visual */
    outline: none;
  }
}

