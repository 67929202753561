.root {
  [class~='MuiPopover-paper'] {
    /* Positioning */
    transform: translateX(
      -16px
    ) !important; /* 1. Used to override inline styles */
  }

  .menuItem {
    /* Box-model */
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 191px;
  }
}
