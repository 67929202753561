@import '../../../assets/styles/settings/colors.module.scss';

$left-padding: 45px;

.Message {
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid $gray-300;
  border-left: 4px solid $gray-300;
  color: $gray-700;
  border-radius: 4px;
}

.small {
  padding: 0;
  padding-left: $left-padding;
}

.medium {
  padding: 15px;
  padding-left: $left-padding;
}

.large {
  padding: 20px;
  padding-left: $left-padding;
}

.success {
  border-color: $state-success-600;
}

.warning {
  border-color: $state-warning-600;
}

.info {
  border-color: $informative-600;
}

.Icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.IconWaring {
  color: $state-warning-600;
}

.IconInfo {
  color: $informative-600;
}

.IconSuccess {
  color: $state-success-600;
}

.close {
  justify-self: flex-end;
  margin: auto;
}
