@import "../../assets/styles/variables.scss";

.Container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}


:export {
    color: $main-color;
}