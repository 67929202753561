.container {
  height: 100%;
  .header {
    padding: 24px;
  }
}

.EmptyMessage {
  margin-top: 15%;
}
