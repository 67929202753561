@import '../../../../assets/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;600&family=Source+Sans+Pro&display=swap');

.container {
  margin-bottom: 14px !important;
  font-size: 12px;
  display: flex;
  flex-flow: wrap;

  .outlineButton {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    min-width: 160px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: white;
    font-size: 16px;
    color: #00d0d4;
    font-family: 'quicksand' !important;
    font-weight: 600 !important;
    margin-right: 10px;
  }

  .button {
    box-sizing: border-box;
    height: 40px;
    min-width: 160px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #e5f5f6;
    font-size: 16px;
    color: #00d0d4;
    font-family: 'quicksand' !important;
    font-weight: 600;
    margin-right: 10px;
  }

  .titleText {
    display: grid;
    text-transform: uppercase;
    font-size: 12px !important;
    place-items: center;
  }
}
