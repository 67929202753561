@import '../../../../../assets/styles/settings/colors.module.scss';

.Container {
  margin-top: 32px;
  margin-left: 24px;
}

.TypographyContainer {
  margin-left: 8px;
  margin-bottom: 16px;
}

.TypographyTitle {
  margin-right: 11px;
}

.totalValueContainer {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-left: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  background-color: $gray-90;
  border-radius: 6px;
}
