@import "../../../assets/styles/variables.scss";

.container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 25px;

  .some {
    .title {
      font-weight: bold;
      font-size: 13px;
      color: $main-color;
      margin-bottom: 13px;
    }

    .value {
      color: $main-color;
      font-weight: 600;
      font-size: 30px;
    }
  }
}
