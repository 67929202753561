@import "../settings/fonts.module.scss";

.headline1 {
  color: $headline1-color;
  font-family: $headline1-font-family;
  font-size: $headline1-font-size;
  font-style: $headline1-font-style;
  font-weight: $headline1-font-weight;
  line-height: $headline1-line-height;
}

.headline2 {
  color: $headline2-color;
  font-family: $headline2-font-family;
  font-size: $headline2-font-size;
  font-style: $headline2-font-style;
  font-weight: $headline2-font-weight;
  line-height: $headline2-line-height;
}

.headline3 {
  color: $headline3-color;
  font-family: $headline3-font-family;
  font-size: $headline3-font-size;
  font-style: $headline3-font-style;
  font-weight: $headline3-font-weight;
  line-height: $headline3-line-height;
}

.headline4 {
  color: $headline4-color;
  font-family: $headline4-font-family;
  font-size: $headline4-font-size;
  font-style: $headline4-font-style;
  font-weight: $headline4-font-weight;
  line-height: $headline4-line-height;
}

.headline5 {
  color: $headline5-color;
  font-family: $headline5-font-family;
  font-size: $headline5-font-size;
  font-style: $headline5-font-style;
  font-weight: $headline5-font-weight;
  line-height: $headline5-line-height;
}

.headline6 {
  color: $headline6-color;
  font-family: $headline6-font-family;
  font-size: $headline6-font-size;
  font-style: $headline6-font-style;
  font-weight: $headline6-font-weight;
  line-height: $headline6-line-height;
}

.subtitle1 {
  color: $subtitle1-color;
  font-family: $subtitle1-font-family;
  font-size: $subtitle1-font-size;
  font-style: $subtitle1-font-style;
  font-weight: $subtitle1-font-weight;
  line-height: $subtitle1-line-height;
}

.subtitle2 {
  color: $subtitle2-color;
  font-family: $subtitle2-font-family;
  font-size: $subtitle2-font-size;
  font-style: $subtitle2-font-style;
  font-weight: $subtitle2-font-weight;
  line-height: $subtitle2-line-height;
}

.body1 {
  color: $body1-color;
  font-family: $body1-font-family;
  font-size: $body1-font-size;
  font-style: $body1-font-style;
  font-weight: $body1-font-weight;
  line-height: $body1-line-height;
}

.body2 {
  color: $body2-color;
  font-family: $body2-font-family;
  font-size: $body2-font-size;
  font-style: $body2-font-style;
  font-weight: $body2-font-weight;
  line-height: $body2-line-height;
}

.caption {
  color: $caption-color;
  font-family: $caption-font-family;
  font-size: $caption-font-size;
  font-style: $caption-font-style;
  font-weight: $caption-font-weight;
  line-height: $caption-line-height;
}
