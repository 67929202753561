.container {
  position: fixed;
  top: 0;
  left: 0;
}

.popper {
  & *:focus {
    outline: none;
  }
}
