@import '../../../assets/styles/settings/colors.module.scss';
@import '../../../assets/styles/settings/fonts.module.scss';

.root {
  display: flex;
  background-color: $gray-100;
  height: 100%;
  overflow: auto;
}

.appBar {
  z-index: 100000;
}

.drawer {
  width: 350px;
  flex-shrink: 0;
}

.drawerPaper {
  width: 350px;
  overflow: auto;
}

.content {
  flex-grow: 1;
}

.amount {
  font-size: $body2-font-size;
  letter-spacing: 0.3;
  margin-left: auto;
  padding-left: 16;
}

.toolbarGradient {
  background: linear-gradient(45deg, $primary-500 0%, $primary-600 100%);
  color: $gray-100;
}

.bgColorPrimary {
  background-color: $primary-500 !important;
}

.bgColorSecondary {
  background-color: $primary-100 !important;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: $gray-800;
}

.titleError {
  @extend .title;
  color: $state-error-600
}

.titleSuccess {
  @extend .title;
  color: $primary-700
}

.titleSuccess {
  @extend .title;
  color: $primary-700
}

.titleBold {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $gray-900;
}

.labelBold {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: $gray-700;
}

.labelBoldSuccess {
  @extend .labelBold;
  color: $primary-700
}

.dlHorizontalValues {
  font-size: 0;

  dt,
  dd {
    width: 48%;
    width: calc(50%);
    margin: 8px 0;
    display: inline-block;
    vertical-align: middle;
  }

  dd {
    text-align: right;
  }

  dt {
    text-align: left;
  }
}

.menuItem {
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-100;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-100 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-100;
}

.textCenter {
  text-align: center;
}

.dlHorizontal {
  font-size: 0;
  text-align: center;

  dt,
  dd {
    width: 68%;
    width: calc(70% - 10px);
    margin: 8px 0;
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    text-align: left;
  }

  dt {
    font-weight: $headline3-font-weight;
    width: 28%;
    width: calc(30% - 10px);
    font-size: 14px;
    padding-right: 10px;
    color: $gray-700;
  }

  dd {
    font-size: 13px;
    font-weight: 300;
    padding-left: 10px;
  }
}
