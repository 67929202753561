@import '../../../../assets/styles/settings/colors.module.scss';

.detailsContainer {
  padding: 0;
  width: 100%;
  overflow-x: auto;
}

.summaryContent {
  padding-left: 10px;
  margin: 10px 0;
}

.accordionSummary {
  flex-direction: row-reverse;
  border: 0;
  border-radius: 3px;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.menuItem {
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-100;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-100 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-100;
}