@import "../../../assets/styles/settings/colors.module.scss";

.switch {
  display: flex;
  align-items: center;
  color: $primary-600;
  width: 450px !important;
  margin-left: 40px !important;
  margin-top: 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.projectionButton {
  background-color: transparent;
  color: $primary-600;
  border: solid 1px $primary-600;
  height: 40px;
  width: 150px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
}

.projectionButton:first-child {
  border-radius: 6px 0px 0px 6px;
  -webkit-border-radius: 6px 0px 0px 6px;
  -moz-border-radius: 6px 0px 0px 6px;
  -ms-border-radius: 6px 0px 0px 6px;
  -o-border-radius: 6px 0px 0px 6px;
  border-right: none;
}

.projectionButton:nth-child(2) {
  border-right: none;
}

.projectionButton:last-child {
  border-radius: 0px 6px 6px 0px;
  -webkit-border-radius: 0px 6px 6px 0px;
  -moz-border-radius: 0px 6px 6px 0px;
  -ms-border-radius: 0px 6px 6px 0px;
  -o-border-radius: 0px 6px 6px 0px;
  border-right: solid 1px $primary-600;
}

.selected {
  background-color: $primary-600;
  color: $gray-000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
}

.disabled {
  color: $gray-500;
  cursor: no-drop;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
}
