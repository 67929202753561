@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  padding-top: 1.0625rem;
  display: flex;

  /* Flexbox Container */
  flex-direction: column;
  align-items: center;
}

.table {
  /* Box-model */
  width: 36.9375rem;

  /* Visual */
  background-color: $gray-000;
  border: 0.0625rem solid $gray-300;

  th[class~='MuiTableCell-root'] {
    /* Box-model */
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  td[class~='MuiTableCell-root'] {
    /* Box-model */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  [class~='MuiTableCell-root'] {
    &:first-child {
      /* Box-model */
      padding-left: 0.8125rem;
    }

    &:last-child {
      /* Box-model */
      padding-right: 1.5rem;
    }
  }
}
