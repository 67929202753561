@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  padding: 1rem 0;

  /* Visual */
  background: $gray-80;
  border: 0.0625rem solid $gray-200;
  border-radius: 0.25rem;
}

.divider {
  /* Box-model */
  margin: 1rem 0;

  /* Visual */
  border-top: 0.0625rem solid $gray-200;
}
