@import '../../../../assets/styles/settings/colors.module.scss';

.container {
  padding: 0;
  width: 100%;
  overflow-x: auto;
}

.scrollBar {
  overflow-y: auto;
  display: flex;
  flex: 1 1 100px;
  flex-direction: row;
  min-width: 0;
  height: 100%;
  &::-webkit-scrollbar {
    width: .5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.4);
    border-radius: 8px;
  }
}

.separator {
  background-color: $gray-000;
}

.boxDetail {
  background-color: $accent-700;
  color: $gray-000;
  border-radius: 4px;
  display: inline-flex;
}

.flex {
  display: flex;
}

.textCenter {
  text-align: center;
}