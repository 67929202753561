@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.projectedBandCell {
  border-left: solid 2px $accent-600;
  height: 16px;
  height: 42px;
}

.projectedTypography {
  color: $accent-600;
  font-style: normal;
  font-weight: 500;
  font-family: $headline-font-family;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.5px;
  height: 42px;
}

.accumulatedTypography {
  color: $gray-900;
  font-style: normal;
  font-weight: 500;
  font-family: $headline-font-family;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.5px;
  height: 42px;
}

.height {
  height: 42px;
}

.borderTop {
  border-top: solid 1px $gray-300;
}
