@import '../../assets/styles/variables.scss';

.Container {
  display: flex;
  text-align: center;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Button {
  margin-top: 10%;
  margin-bottom: 10px;
}

.Link {
  text-decoration: none;
}
