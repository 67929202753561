@import '../../../../assets/styles/settings/colors.module.scss';
@import '../../../../assets/styles/tools/colorFunctions.module.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  width: 100%;
  align-items: center;
}

.typography {
  margin: 20px 100px;
  text-align: center;
}

.uploadButton {
  margin-top: 30px;
  margin-bottom: 68px;
}
