@import '../../../assets/styles/settings/colors.module.scss';

.PlusInput {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 134px;
  max-height: 48px;
  border-radius: 6px;
  background-color: rgba($primary-600, 0.1);
}

.PlusInputFullWidth {
  @extend .PlusInput;
  max-width: inherit;
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  background-color: rgba($primary-600, 0.1);
}
.Icon {
  color: $primary-600;
  padding: 8px;
  &:focus,
  & *:focus {
    outline: none;
  }
}

.IconSmall {
  @extend .Icon;
  padding: 4px;
}

.Input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0 4px 0;
  min-width: 50px;
  background-color: white;
  min-height: 40px;
  text-align: center;
  border: 1px solid $gray-400;
  border-radius: 4px;
  & input {
    text-align: center;
  }
}

.Small {
  min-height: 36px;
  max-width: 114px;
  max-height: 36px;
}

.SmallInput {
  min-width: 50px;
  min-height: 32px;
  max-width: 50px;
  max-height: 32px;
}
