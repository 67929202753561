@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.header {
  width: 100%;
  padding: 0 0 20px;
  display: inline-table;

  h1 {
    /* Box-model */
    margin-top: 0;
    margin-bottom: 24px;

    /* Flexbox item */
    flex: none;
  }

  > .divider {
    margin: 10px 0;
  }
}

.tools {
  /* Box-model */
  display: flex;

  /* Flexbox Container */
  align-items: center;
}

.filter {
  /* Box-model */
  display: flex;
  
  /* Flexbox container */
  align-items: center;

  /* Flexbox item */
  flex: none;
}

.buttons {
  /* Box-model */
  display: flex;

  /* Flexbox Container */
  justify-content: flex-end;

  /* Flexbox item */
  flex: 1 0 auto;

  & > * + * {
    /* Box-model */
    margin-left: 38px;
  }
}

.button {
  /* Box-model */
  padding: 12px 24px;

  /* Typography */
  color: $primary-600;
  font-family: $button-small-font-family;
  font-size: $button-small-font-size;
  font-weight: 600;
  letter-spacing: $button-small-letter-spacing;
  line-height: 1;
  text-transform: capitalize;

  &:hover {
    /* Visual */
    background-color: #{$primary-500}17;
  }

  &:focus {
    /* Visual */
    background-color: $primary-300;
    outline: none;
  }

  &:active {
    /* Typography */
    color: $primary-800;
    background-color: initial;
  }
}

.buttonOutlined {
  /* Box-Model */
  padding: 10px 22px;

  /* Visual */
  border: 2px solid $primary-600;
}

@media (max-width: 1439px) {
  .tools {
    /* Box-model */
    height: 72px;

    /* Visual */
    border-top: 1px solid $gray-300;
  }
}

@media (min-width: 1440px) {
  .header {
    /* Box-model */
    height: 100px;
  
    /* FLexbox container */
    flex-direction: row;
    align-items: center;

    h1 {
      /* Box-model */
      margin-bottom: 0;
    }
  }

  .tools {
    /* Box-model */
    margin-left: 86px;

    /* Flexbox item */
    flex: 1 0 auto;
  }

  .buttons {
    & > * + * {
      /* Box-model */
      margin-left: 18px;
    }
  }

  .button {
    /* Box-model */
    padding: 15px 32px;
  
    /* Typography */
    font-size: $button-medium-font-size;
    letter-spacing: $button-medium-letter-spacing;
  }

  .buttonOutlined {
    /* Box-Model */
    padding: 13px 30px;
  }
}