.dialogContent {
  width: 454px;
  height: 100px;
}

.dialogContentText {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.textField {
  width: 330px;
}

.dialogActions {
  margin-top: 20px;
  margin-bottom: 32px;
  margin-right: 32px;
}
