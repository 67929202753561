@import '../../../../../assets/styles/settings/colors.module.scss';

.Chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray-600;
  border-radius: 16px;
  padding: 3px;
  padding-left: 12px;
  font-weight: 400;
  margin-right: 10px;
  margin-top: 8px;
}

.Button {
  padding: 4px;
  margin-left: 8px;
  background-color: $gray-400;

  &:hover {
    background-color: $gray-300;
  }

  &:focus,
  & *:focus {
    outline: none;
  }
  & * {
    font-size: 1rem;
  }
}
