.container {
  position: relative;

  &:hover .noHover {
    filter: blur(3px);
  }

  &:hover .hover {
    opacity: 1;
  }

  .hover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0;
  }

  .noHover {
    transition: filter 150ms;
    opacity: 1;
  }
}
