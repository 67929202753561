@import "../../../../assets/styles/settings/colors.module.scss";

.resultStateContainer {
  display: flex;
  justify-content: center;
}

.resultStateTableContainer {
  max-width: 1300px;
}

.resultStateTable {
  margin-bottom: 80px;
}
