@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/fonts.module.scss';
@import '../../../../../assets/styles/components/modal.module.scss';

.root {
  .emptyMessage {
    /* Typography */
    text-align: center;
  }
}

.addButton {
  /* Box-model */
  display: flex;
  margin-top: 16px;
  margin-left: $modal-gutter;
  padding: 6px 14px;

  /* Flexbox Container */
  justify-content: flex-start;

  /* Typography */
  color: $primary-600;
  font-family: $body2-font-family;
  font-size: $body2-font-size;
  font-weight: 500;
  letter-spacing: $body2-letter-spacing;
  line-height: 1rem;
  text-transform: capitalize;

  &:hover {
    /* Visual */
    background-color: #{$primary-500}17;
  }

  &:focus {
    /* Visual */
    background-color: $primary-300;
    outline: none;
  }

  &:active {
    /* Typography */
    color: $primary-800;
    background-color: initial;
  }

  &.isOptionsEmpty {
    /* Box-model */
    margin-left: auto;
    margin-right: auto;
  }
}

.addIcon {
  /* Box-modal */
  margin-right: 8px;
}
