.container {
  //.text-gray-700
  color: #4a5568;
  // .font-sans
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  // .antialiased
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // base
  font-size: 14px;
  // .p-4
  padding: 1em;
  // .overflow-y-auto
  overflow-y: auto;

  .header {
    // .mb-4
    margin-bottom: 1em;
    // .flex
    display: flex;

    .title {
      // .font-bold
      font-weight: 700;
      // .text-3xl
      font-size: 1.875rem;
    }

    .actions {
      // .mr-0
      margin-right: 0;
      // .ml-auto
      margin-left: auto;
    }
  }

  .ratings {
    .onBoarding {
      position: relative;
      .message {
      }
    }
  }
}
