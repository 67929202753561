@import '../../../assets/styles/variables.scss';

.ReactVirtualizedTableheaderRow {
  background-color: $base-color;
  color: $main-color;
  margin-right: 0px !important;
  align-content: center;
  justify-content: center;
  display: flex;
}

.Cell {
  justify-content: center;
  display: flex;
}
