@import '../../../../../assets/styles/variables.scss';

$width: 25px;
$height: 25px;
$border-radius: 5px;

.GreenHelper {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-green-color;
  border-radius: $border-radius;
}
.YellowHelper {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $secondary-color;
  border-radius: $border-radius;
}
.BlueHelper {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-aqua-blue-dark-color;
  border-radius: $border-radius;
}
