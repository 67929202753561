@import '../../../../assets/styles/settings/colors.module.scss';

.PlusInput {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 134px;
  max-height: 38px;
  border-radius: 6px;
  background-color: rgba($primary-600, 0.1);
  margin: -10px -9px -8px -14px;
}

.Icon {
  color: $primary-600;
  padding: 10px;
  &:focus,
  & *:focus {
    outline: none;
  }
}

.Input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0 4px 0;
  min-width: 50px;
  background-color: white;
  min-height: 26px;
  text-align: center;
  border: 1px solid $gray-300;
  & input {
    text-align: center;
  }
}
