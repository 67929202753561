@import "../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../assets/styles/settings/fonts.module.scss";

.virtualTableCell {
  height: 42px;
  border-right: solid 1px $gray-300;
  background-color: $gray-000;
  padding: 0%;
  margin: 0%;
}

.virtualTableCell:first-child {
  height: 42px;
  background-color: $gray-000;
}

.typographyRow {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: $gray-800;
  padding: 0px 8px;
  margin: 0%;
}

.leftColumn {
  height: 42px;
  border-right: solid 1px $gray-300;
  background-color: $gray-000;
  padding: 0%;
  margin: 0%;
}

.leftTypography {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: $gray-800;
  padding: 0px 8px;
  margin: 0%;
}

.utilityRow {
  height: 42px;
  background: $success-200;
  border-right: solid 1px $gray-300;
  padding: 0%;
  margin: 0%;
}

.utilityTypography {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: $gray-900;
  padding: 0px 8px;
}
