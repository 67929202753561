.dialogContainer{
  margin-top: 100px;
  margin-top: 8%;
  max-height: 550px;
}

.title{
  text-align: center;
}

.DialogContent{
  display:flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.margin{
  margin-bottom:5%;
}