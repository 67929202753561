@import '../../assets/styles/variables.scss';

.Sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 5rem;
  flex-shrink: 0;
  background: $main-color;
  height: 100%;
  width: 80px;
}

.Start {
  margin-top: 3vh;
}

.End {
  margin-top: auto;
  margin-bottom: 3vh;
}

.logo {
  height: 40px;
  width: 70px;
}

.MenuItem {
  display: flex;
  justify-content: center;

  a:link,
  a:visited {
    border: none;
    color: $white-color;
    padding: 10px 10px;
    transition-duration: 0.4s;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
  }
  a:hover,
  a:active {
    background-color: $gray-color;
    border-radius: 50%;
  }
}
