@import '../../../../assets/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap');

.virtualCell {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0%;
  border-top: none;
  border-bottom: none;
  height: 30px;
  width: 125px;
  border-left: 10px solid transparent;
}

.floorCell {
  padding: 0%;
  width: 30px;
  height: 30px;
  border: none;
}

.floor {
  width: 30px !important;
  color: #232e39;
  font-family: 'quicksand';
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
}

.available {
  height: 30px;
  width: 125px;
  opacity: 0.4;
  background-color: #00dd6d;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 5px;
}

.availableColor {
  color: #00a3a9;
  font-family: 'quicksand';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  height: 30px;
  padding-top: 10px;
}

.optional {
  height: 30px;
  width: 125px;
  opacity: 0.4;
  background-color: #377476;
  text-align: center;
  position: absolute !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 5px;
}

.optionalColor {
  color: #377476;
  font-family: 'quicksand';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  height: 30px;
  padding-top: 10px;
}

.sold {
  height: 30px;
  width: 125px!important;
  background-color: #d8d8d8;
  text-align: center;
  position: absolute !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 5px;
}

.soldColor {
  color: #7d848b;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  height: 30px;
  padding-top: 10px;
  position: absolute;
}

.clientBadge {
  height: 30px;
  width: 125px;
  opacity: 0.4;
  background-color: #aa0073;
  position: absolute !important;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 5px;
}

.clientBadgeColor {
  color: #aa0073;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  height: 30px;
  padding-top: 10px;
}

.badgeDesist {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  right: 6px;
  top: 0px;
  color: aliceblue;
  font-size: 10px;
  z-index: 9;
}

.numberFormatAvailableColor {
  color: #00a3a9;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.numberFormatSoldColor {
  color: #7d848b;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  height: 30px;
  padding-top: 10px;
  position: absolute;
}

.numberFormatOptionalColor {
  color: #377476;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  position: absolute;
}

.numberFormatClientBadgeColor {
  color: #aa0073;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  position: absolute;
}

.emptyCell {
  border: none;
  height: 30px;
  padding: 0%;
}

.buttonCell {
  height: 30px;
  width: 115px;
  justify-content: center;
}

.groupSelected {
  font-size: 11px;
}
