@import '../../../../../assets/styles/settings/colors.module.scss';

$popover-background-color: $gray-000;
$popover-arrow-width: 1.75rem;
$popover-arrow-height: 1.125rem;
$box-shadow: 0rem 0.0625rem 0.0625rem -0.0625rem #6985a1,
  0rem 0.25rem 0.75rem rgba(161, 171, 181, 0.25);

.root {
  [class~='MuiPopover-paper'] {
    /* Box-model */
    margin-top: 1.375rem;
    padding: 1.5rem 2rem;
    overflow: initial;

    /* Visual */
    background-color: $popover-background-color;
    box-shadow: $box-shadow;
    outline: none;
  }
}

/* Add nice pointer :D */
.arrow {
  /* Positioning */
  position: absolute;
  top: -$popover-arrow-height;
  right: 6.25rem;

  /* Visual */
  filter: drop-shadow(0rem -0.75rem 0.75rem rgba(161, 171, 181, 0.25));

  &::before {
    /* Box-model */
    content: '';
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;

    /* Visual */
    background-color: $popover-background-color;
    clip-path: polygon(50% 0, 100% 100%, 0% 100%);
  }
}

.errorMessage {
  /* Box-model */
  display: inline-flex;
  margin-bottom: 1.5rem;
  padding: 0.875rem 3rem 0.875rem 1.125rem;

  /* Flexbox Container */
  align-items: center;

  /* Visual */
  background-color: $gray-000;
  border-radius: 0.25rem;
  filter: drop-shadow(0rem 0.0625rem 0.375rem rgba(161, 171, 181, 0.4));
}

div.errorIcon {
  /* Box-model */
  margin-right: 0.625rem;
}

.cards {
  /* Box-model */
  display: flex;

  & > * + * {
    /* Box-model */
    margin-left: 1rem;
  }
}
