@import "../../assets/styles/variables.scss", "../UI/Card/Card.module.scss";

.Container {
  display: flex;
  text-align: center;

  label {
    color: $red-color;
  }
}

.Input {
  margin: 15px 30px;
  font-size: 30px;
}

.Button {
  margin-top: 10%;
}

.Title {
  margin: 15px 30px;
}