@import '../../../../../assets/styles/variables.scss';

.inputContainer {
  display: grid;
  grid-template-columns: 25% 75%;
  place-content: center;
  text-align: center;
}

.textField {
  display: grid;
  place-items: center;
}

.inputLabel {
  background-color: $base-color !important;
  font-size: 16px !important;
}
