@import '../../../../../assets/styles/settings/colors.module.scss';

.Card {
  border-radius: 0px;
  border: 1px solid $gray-300;
  border-bottom: 0;
}

.Icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 50%;
  margin-bottom: 1px;
  border: 4px solid $gray-000;
}

.IconSuccess {
  @extend .Icon;
  background-color: $state-success-600;
  border-color: $state-success-400;
}
.IconWarning {
  @extend .Icon;
  background-color: $state-warning-600;
  border-color: $state-warning-400;
}
.IconError {
  @extend .Icon;
  background-color: $state-error-600;
  border-color: $state-error-300;
}

.Button {
  &:focus,
  & *:focus {
    outline: none;
  }
}

.Label {
  color: $gray-600;
}
