@import '../../../assets/styles/variables.scss';

.Dashboard {
  display: flex;
  height: 100%;
  overflow: hidden;

  .Navigation {
    display: flex;
    flex-flow: row;
  }

  .Content {
    padding: $margin-content;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;

    &.removePadding {
      /* Box-model */
      padding: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
}

.blueBackground {
  background-color: $aquamarine-background;
}

.grayBackground {
  background-color: #e0e0e4;
}
