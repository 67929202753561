@import '../../styles/DeedExpenses.modules.scss';

.DeedExpensesSalesMadeEmptyState {
  margin: 0 $deed-expenses-margin 0 $deed-expenses-margin;
  padding: $deed-expenses-padding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
