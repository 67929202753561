@import '../../../assets/styles/settings/colors.module.scss';

.root {
  display: flex;
  background-color: $gray-000;
  height: 100%;
  overflow: auto;
  padding: 0px;
  margin: 0px;
}

.appBar {
  z-index: 10000;
}

.toolbar {
  background: linear-gradient(45deg, $primary-500 0%, $primary-600 100%);
  color: $gray-100;
}
