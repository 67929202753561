.container {
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.25rem; }
  .container .header {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 1.25em; }
  .container .primes {
    display: flex;
    font-size: 16px; }
    .container .primes .prime {
      display: flex;
      margin-right: 15px; }
      .container .primes .prime .title {
        margin-right: 15px; }
