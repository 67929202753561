.button {
  // Fixed
  font-size: inherit;
  font-family: inherit;
  border: none;
  // .bg-gray-300
  background-color: #e2e8f0;
  // .text-gray-800
  color: #2d3748;
  // .font-bold
  font-weight: 700;
  padding: 0.5em 1em;
  margin: 0 0.25em;
  // .rounded
  border-radius: 0.25em;
  &:hover {
    background-color: #cbd5e0;
  }

  &:disabled {
    background-color: #aab2bb;
  }
}
