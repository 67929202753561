.title {
  /* Box-model */
  display: flex;
}

div.value {
  /* Box-model */
  margin-top: 0.25rem;

  &:empty::before {
    /* Box-model */
    content: '--';
  }
}
