@import '../../../../../../assets/styles/settings/colors.module.scss';

.container {
  text-align: center;
}
.success {
  color: $state-success-600;
}
.warning {
  color: $state-error-600;
}
