.root {
  /* Box-model */
  overflow: hidden;
  padding-top: 1.5rem;
  padding-left: 2.5rem;

  /* Flexbox Item */
  flex: auto;
}

.tableContainer {
  /* Box-model */
  display: flex;
  overflow: auto;
  height: 100%;
}

.partialTable {
  & + .partialTable {
    /* Box-model */
    margin-left: 0.5rem;
  }

  &:last-child {
    /* Box-model */
    margin-right: 2.135rem;
  }
}
