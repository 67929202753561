@import "../../../../assets/styles/settings/colors.module.scss";

.groupRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-000;
  font-size: 16px;
  font-weight: 500;
  height: 42px;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.subGroupRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-90;
  height: 42px;
  color: $gray-700;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.itemRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;
  height: 42px;
  color: $gray-700;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.builderCreditBalance {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $informative-500;
  background-color: $informative-90;
  height: 42px;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.subBuilderCreditBalance {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $informative-400;
  background-color: $informative-80;
  height: 42px;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.cellConfiguration {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
}

.cashBalance {
  background-color: $success-200;
  border-left: 1px solid $gray-300;
}

.boldRows {
  color: $gray-900;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}

.salesAndExpenses {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-000;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 42px;
  color: $gray-800;
}
