@import '../../../assets/styles/settings/colors.module.scss';

.DialogContent {
  padding: 0;
}

.BoxTitle {
  background-color: $primary-100;
  color: $primary-800;
  border-radius: 4px;
}

.outlineNone {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.amount {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.switch {
  border-radius: 20px;
  color: $gray-600;
  background-color: $gray-200;
  border: none;
  
  & label {
    line-height: 40px;
  }
  & label:before {
    border-radius: 20px;
    color: $primary-600;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  }
}

.CloseIcon {
  position: absolute;
  right: 1rem;
}