@import "../../../assets/styles/settings/colors.module.scss";

.circularProgress {
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  margin: 5% 0;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.paperContainer {
  padding: 1% !important;
  background-color: $gray-90;
  width: 1283px;
  height: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.switchContainer {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2% 0 0% 0;
  border: 0px !important;
}

.card {
  background-color: $gray-000;
  border-radius: 4px;
  width: 560px;
  height: 148px;
  left: 72px;
}

.card:first-child {
  margin-bottom: 16px;
  margin-right: 16px;
}

.card:nth-child(2) {
  margin-bottom: 16px;
}

.card:nth-child(3) {
  margin-right: 16px;
}

.cardContent {
  width: 560px;
  height: 148px;
  padding: 0%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.typography {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-left: 40px;
  padding-top: 24px;
  padding-bottom: 5px;
}

.formGroup {
  padding-left: 16px !important;
}

.constructionSwitch {
  width: 300px !important;
}
