.DeedExpensesSalesMadeTableHederCellContent {
  padding-left: 18px;
}

.Icon {
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.More {
  cursor: pointer;
}

.IconLeft {
  @extend .Icon;
  left: 12px;
}
