@import '../../../../assets/styles/settings/colors.module.scss';

.switch {
  border-radius: 20px;
  color: $gray-600;
  background-color: $gray-200;
  border: none;
  
  & label {
    line-height: 40px;
  }
  & label:before {
    border-radius: 20px;
    color: $primary-600;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  }
}
