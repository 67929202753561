@import "../../../assets/styles/variables.scss";

.Container {
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  justify-content: center;
  animation: slideDown 3.4s ease 2;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  z-index: 1000;
}


.Alert {
  background: $alert-color;
  border-radius: 4px;
  box-shadow: 0px 0px 13px 0px $box-shadow-color;
  margin: 25px 0;
  padding: 25px 40px;
  opacity: 1;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
