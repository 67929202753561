.SearchFieldRoot {
  padding: 2px 14px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.SearchFieldRoot_input {
  margin-left: 8px;
  flex: 1;
}