@import "../../../assets/styles/variables.scss";

@mixin Card {
  background: $white-color;
  border-radius: 4px;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
  margin: 25px 0;
  padding: 25px 40px;  
}

.Card {
  @include Card
}

.Loader {
  display: flex;
  justify-content: center;
}
