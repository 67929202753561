@import "../../../assets/styles/settings/colors.module";

.root {
  /* Custom Properties */
  --backgroun-color: #{$gray-100};

  /* Box-model */
  display: inline-flex;
  padding: 0 16px;
  height: 24px;

  /* Flexbox container */
  align-items: center;

  /* Typography */
  white-space: nowrap;

  /* Visual */
  background-color: var(--backgroun-color);
  border-radius: 12px;
}

.label {
  /* Box-model */
  overflow: hidden;
  
  /* Typography */
  text-overflow: ellipsis;
  white-space: nowrap;
}