.autocompleteContainer {
  background-color: white;
  width: 362px;
}

.excelButtonsContainer {
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
