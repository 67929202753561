@import '../../../assets/styles/settings/colors.module.scss';

.content {
  padding: 0px;
}

.title {
  margin-bottom: 30px;
  margin-left: 155px;
}

.paymentSegmentContainer {
  display: flex;
  background-color: $gray-000;
  height: 100%;
  overflow: auto;
  padding: 0px;
  margin: 0px;
}
