@import '../../../../../assets/styles/settings/colors.module.scss';

.DeedExpensesDeedDateFormatter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Icon {
  color: $state-warning-600;
}
