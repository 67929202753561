@import '../../../assets/styles/settings/colors.module.scss';
@import './styles/settings.scss';

.root {
  /* Box-model */
  display: flex;
  padding-top: 2.5rem;
  height: 100%;

  /* Flexbox Container */
  flex-direction: column;

  /* Visual */
  background-color: $stage-view-background-color;
}

.header {
  /* Box-model */
  margin: 0 $stage-view-gutter 0.8125rem;
}

.loader {
  /* Box-model */
  margin: auto;
}
