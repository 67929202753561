@import '../../../../../assets/styles/variables.scss';

.indicatorContainer {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
}

.indicator {
  display: flex;
  align-items: center;
}

.structuringBox {
  width: 20px;
  height: 20px;
  background-color: $secondary-color;
  margin-left: 5px;
  border-radius: 3px;
}

.presalesBox {
  width: 20px;
  height: 20px;
  background-color: $main-color;
  margin-left: 5px;
  border-radius: 3px;
}

.constructionBox {
  width: 20px;
  height: 20px;
  background-color: $main-aqua-blue-dark-color;
  margin-left: 5px;
  border-radius: 3px;
}

.deedBox {
  width: 20px;
  height: 20px;
  background-color: $ternary-color;
  margin-left: 5px;
  border-radius: 3px;
}

.root {
  width: 100%;
}

.container {
  max-height: 540px;
}

.stickyCell {
  position: -webkit-sticky !important;
  position: sticky !important;
  background: $base-color !important;
  left: 0 !important;
  z-index: 1 !important;
  padding: 0px;
}

.columnDates {
  padding: 2px;
}

.noCompanySelected {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  color: #ff0000;
  font-size: 2rem;
}
