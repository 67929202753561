@import '../../../../../assets/styles/settings/colors.module.scss';

.TextFieldDisabled {
  max-width: 134px;
  max-height: 40px;
  background-color: $gray-200;
  border: 1px solid $gray-400;
  border-radius: 4px;
  color: $gray-600;
  padding: 10px 16px 10px 16px;
}
