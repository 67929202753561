@import '../../assets/styles/variables.scss';

.SideMenu {
  display: flex !important;
  flex-direction: column;
  flex-basis: 5rem;
  flex-shrink: 0;
  color: #fff !important;
  background: $main-color;
  height: 100%;
  flex: 0.8;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 0.2s ease;
  -webkit-box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  box-shadow: 6px 3px 11px -4.5px rgba(0, 0, 0, 0.1);
  border: red;
  &:hover,
  .collapseAndExpandRow {
    -webkit-box-shadow: 6px 3px 11px -4.5px $main-color;
    -moz-box-shadow: 6px 3px 11px -4.5px $main-color;
    box-shadow: 6px 3px 11px -4.5px $main-color;
  }
  z-index: 5;
  overflow-y: scroll;
}

.ZeroWidth {
  width: 0px;
}

.OriginalWidth {
  width: 240px;
  overflow-y: auto;
}

.iconsRoutes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 22px;
  place-items: center;
  a:link,
  a:visited {
    padding: 15px 16px;
    flex: 1;
    border: none;
    transition-duration: 0.4s;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
  }
  a:focus {
    color: $white-color;
  }
  a:hover,
  a:active {
    border: 2px solid $softGray-color;
  }
}

.titleWithImageContainer {
  display: grid;
  grid-template-columns: 35% 65%;
  column-gap: 2%;
  place-items: center;
  color: #f5f5f5;
  background-color: $main-color !important;
  -webkit-box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57) !important;
  -moz-box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57) !important;
  box-shadow: 0px 12px 33px -10px rgba(0, 0, 0, 0.57) !important;
}

.imgLogo {
  height: 60px;
  width: 90px;
  padding-left: 10%;
}

.title {
  padding: 15px 2px 15px 2px;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  color: #f5f5f5;
  background-color: $main-color !important;
  p {
    margin: 0;
  }
}

.towerName {
  opacity: 1 !important;
}

.collapseHeader {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.iconLabel {
  font-size: 19px !important;
  margin-top: 1.5% !important;
  margin-right: 3.5% !important;
  padding-right: 5% !important;
}

.lineSpace {
  margin: 0.05% !important;
  opacity: 0.15 !important;
}

.IconsContainer {
  z-index: 3;
}

.Description {
  color: #f5f5f5;
  margin-left: 0.5%;
  background-color: $main-color;
}

.DescriptionActive {
  color: #f5f5f5;
  background-color: $main-color;
  margin-left: 0.5%;
}

.Start {
  margin-top: 3vh;
}

.End {
  margin-top: auto;
  margin-bottom: 3vh;
}

.MenuItem {
  display: flex;
  justify-content: center !important;
  width: 100vw !important;
  font-size: 13px !important;
  z-index: 3;
  color: #ffff;
  background-color: $main-color;
  width: 100vw !important;
  a:link,
  a:visited {
    padding: 15px 16px;
    flex: 1;
    border: none;
    transition-duration: 0.4s;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    width: 100vw !important;
  }
  a:focus {
    color: $white-color;
  }
  a:hover,
  a:active {
    width: 100vw !important;
    border-left: 5px solid $softGray-color;
  }
}

.Active {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: $main-color !important;
  font-size: 15px !important;
  a:link,
  a:visited {
    flex: 1;
    border: none;
    color: $white-color;
    border-left: 5px solid $softGray-color;
    padding: 10px 2px;
    transition-duration: 0.4s;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
  }

  a:hover,
  a:active {
    border-left: 5px solid $softGray-color;
  }
}

.fixedWidth {
  min-width: 150px;
  overflow-x: hidden;
}

.Visible {
  display: flex !important;
}
.NoVisible {
  display: none !important;
}

.principalBadge {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: rgb(170, 6, 6);
  overflow: hidden;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: $red-color;
  top: 5px;
  left: -5px;
}

.Badge {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: rgb(170, 6, 6);
  overflow: hidden;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  background: $red-color;
  top: -10px;
}

.header {
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  margin: 15px 0;
  padding-left: 15px !important;
  opacity: 0.45;
}

.expansionPanel {
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  color: $white-color;
  .expansionPanel:hover {
    background-color: $main-color !important;
  }
}

.expansionPanelActive {
  color: $white-color !important;
}

.expansionPanel:before {
  background-color: transparent !important;
}

.iconColor {
  color: $white-color;
}

.expansionPanelSummary {
  margin: 0 !important;
  padding: 0 10px !important;
  box-sizing: content-box;
  font-size: 16px;
  &:hover {
    background-color: $main-color !important;
  }
}

.expansionPanelDetails {
  padding: 0 !important;
  margin-bottom: 15px;
  background-color: $itemNoActive !important;
  color: #f5f5f5;
}

.linkContainer {
  display: flex;
  flex-direction: column;
}
.container {
  height: 100vh;
}
