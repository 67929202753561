@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  padding: 0.1875rem 0;

  /* Typography */
  text-align: center;

  /* Visual */
  background-color: $gray-600;
  border: none;

  &:first-child {
    /* Box-model */
    padding-left: 0;
  }

  &:last-child {
    /* Box-model */
    padding-right: 0;
  }

  &.isEmpty {
    /* Visual */
    background-color: transparent;
  }
}
