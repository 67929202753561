@import "../../../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../../../assets/styles/settings/fonts.module.scss";

.bandCellContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cutCell {
  background-color: $primary-600;
  border: 1px solid $primary-600;
  height: 40px;
  margin: 0%;
  padding: 0%;
}

.errorCell {
  background-color: $state-error-700;
  border-bottom: 1px solid $state-error-700;
  height: 40px;
  margin: 0%;
  padding: 0%;
}

.CutTypography {
  color: $gray-000;
  font-style: normal;
  font-weight: 500;
  font-family: $headline-font-family;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.5px;
  height: 100%;
  margin-left: 10px;
  width: auto;
}

.budgetTypography {
  color: $gray-900;
  font-style: normal;
  font-weight: 500;
  font-family: $headline-font-family;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.5px;
  height: 42px;
}

.emptyCell {
  height: 42px;
  background: $gray-000;
  border: none;
}

.borderTop {
  border-top: solid 1px $gray-300;
}

.budgetSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-600;
  height: 34px;
}

.budgetSelectorTypography {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $gray-000;
}

.arrowColor {
  color: $gray-000;
}

.tooltipTypography {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.deleteButton {
  background-color: $state-error-100;
  color: $state-error-600;
}

.eventNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
