@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../styles/DeedExpenses.modules.scss';

.Content {
  display: flex;
  min-height: 130px;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: $deed-expenses-margin $deed-expenses-margin 0 $deed-expenses-margin;
  padding: 60px $deed-expenses-padding 0 $deed-expenses-padding;
  background: $gray-000;
  border-bottom: 1px solid $gray-300;
}
