@import "../../../assets/styles/settings/colors.module.scss";

.root {
  /* Box-model */
  min-height: auto;

  [class~=MuiTabs-indicator] {
    /* Visual */
    background-color: $primary-600;
  }
}
