@import '../../../../../../assets/styles/settings/colors.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.icon button:focus {
  outline: none;
  & svg {
    color: $primary-600;
  }
}
