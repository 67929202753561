@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/screenSize.module.scss';
@import '../../../../../assets/styles/tools/breakpoints.module.scss';

.root {
  /* Box-model */
  padding: 2rem 0 3.75rem 2.5rem;
  width: 20.125rem;
  height: 100%;
  overflow-y: auto;

  /* Visual */
  background-color: $gray-000;
  border-right: 0.0625rem solid $gray-300;
}

.content {
  /* Box-model */
  width: 15.9375rem;
}

.globalValue {
  & + .globalValue {
    /* Box-model */
    margin-top: 1rem;
  }
}

.phaseSummary {
  /* Box-model */
  margin-top: 1.25rem;

  & + .phaseSummary {
    /* Box-model */
    margin-top: 1rem;
  }
}

@include lg--min {
  .root {
    /* Box-model */
    padding: 2rem 1.6875rem 3.75rem 2.5rem;
    width: 23.4375rem;
  }
}

@include xl--min {
  .root {
    /* Box-model */
    width: 31.8125rem;
  }
}
