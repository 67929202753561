@import '../../assets/styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap');

.projectsContainer{
  background-color: $aquamarine-background;
  width: 100%;
  height: auto;
  padding-top: 2.5%;
  border: 0%;
  box-shadow: 0px 0px 0px 0px, 0px 0px 0px 0px ;
}

.cardContent{
  display: flex;
  justify-content: center !important;
  padding: 0%;
  margin: 0%;
}

.ItemContainer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  border-radius: 5% !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: $softGray-color !important;
  background: $main-color !important;
  flex: 1 1 140px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  max-height: 220px !important;
  min-height: 220px !important;
  margin: 10px;
  box-sizing: border-box;

  transition: box-shadow 0.3s ease-in-out 0s, background-color 0.6s ease-in 0s !important;
  z-index: 0 !important;

  &:focus {
    outline: auto 5px;
  }

  &:hover {
    box-shadow: rgb(179, 186, 197) 0px 4px 8px 0px,
      rgb(179, 186, 197) 0px 0px 1px 0px;
    text-decoration: none;
  }
}

.noBlue {
  text-decoration: none;
  color: inherit;
}

.DescriptionItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 5px;
  z-index: 9999 !important;
  color: $description-color;
  background-color: $white-color;
  padding-bottom: 0%;
  justify-content: flex-end;
}

.Item {
  display: flex;
  flex-direction: column;
  color: inherit;
  height: 30%;
  background: $white-color;
  padding: 0px 5px 3px 5px;
}

.projectDescription{
  margin-top: 0%;
  padding: 3%;
  color: $name-color;
  font-size: 14px;
  font-weight: 600;
}

.Buttons {
  display: flex;
  flex-direction: row;
  color: inherit;
  align-items: center;
  justify-content: flex-end;
  height: 30%;
}

.trashButton{
  color: #434C53;
}

.Projects {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: 'quicksand' !important;
}

.Remove {
  align-self: flex-end;
  justify-self: end;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;

  &:hover {
    opacity: 0.8;
  }

  i {
    font-size: 0.9rem;
  }
}

.Description {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding-bottom: 0% !important;
  margin: 0%;
  padding-left: 3.5%;
}

.projectName{
  margin-bottom: 0%;
  padding: 0% 3%;
  font-weight: 600;
  color: #434C53;
  font-size: 20px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin: 2% !important;
  padding: 0% 4.8%;
}

.header {
  color: #FFFFFF;
  width: 95%;
  padding-left: 4.8%;
  font-weight: bold;
}

.addButton{
  width: 1,2rem;
  height: auto;
  margin: 0px;
}

.rightMenuOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divSelect {
  width: 100%;
  text-align: center;
}

.rootSelect {
  width: 238px !important;
  margin: 0.3% 0 0 0 !important;
  font-size: 15px !important;
}

.icon{
  color: black;
}