/**
 * Requires:
 * /settings/screenSize.module
 */

// Medium devices
@mixin md--min {
  @media (min-width: #{$screen-md--min}) {
    @content;
  }
}

@mixin md--max {
  @media (max-width: #{$screen-md--max}) {
    @content;
  }
}

// Large devices
@mixin lg--min {
  @media (min-width: #{$screen-lg--min}) {
    @content;
  }
}

@mixin lg--max {
  @media (max-width: #{$screen-lg--max}) {
    @content;
  }
}

// Extra large devices
@mixin xl--min {
  @media (min-width: #{$screen-xl--min}) {
    @content;
  }
}

@mixin xl--max {
  @media (max-width: #{$screen-xl--max}) {
    @content;
  }
}
