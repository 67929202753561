@import '../../../../assets/styles/settings/colors.module.scss';

.content {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 75px;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 8px;
    border: 4px solid $gray-300;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-300;
  }
}
