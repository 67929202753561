@import '../../../assets/styles/settings/colors.module.scss';

.header {
  background: $gray-000;
  padding-top: 30px;
  padding-bottom: 16px;
}

.tableContainer {
}

.tableHeader {
  display: flex;
  border-bottom: 1px solid $gray-300;
  margin-bottom: 17px;
  padding: 16px 0px 0px;
}

.titleButton {
  padding: 8px 16px;
}

.titleButtonContainer {
  display: flex;
  flex-direction: column;
}

.bottomLine {
  width: 100%;
  height: 2px;
}

.bottomLineWithColor {
  background-color: $primary-600;
}

.buttonFilter {
  background: inherit;
  border: 0px;
  cursor: pointer;
}
