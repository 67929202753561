$primary-900: #042825;
$primary-800: #215153;
$primary-700: #267373;
$primary-600: #00a3a9;
$primary-500: #31cdd3;
$primary-400: #1ae6e5;
$primary-300: #9be4e4;
$primary-200: #cbf4f4;
$primary-100: #f2fafb;

$accent-900: #442b3e;
$accent-800: #660045;
$accent-700: #a80072;
$accent-600: #df53a7;
$accent-500: #f579c3;
$accent-400: #fea0d9;
$accent-300: #ffc3e7;
$accent-200: #ffddf1;
$accent-100: #feecf7;

$gray-900: #232e39;
$gray-800: #434c53;
$gray-700: #596a7c;
$gray-600: #6c8a9b;
$gray-500: #a1abb5;
$gray-400: #c2ccd6;
$gray-300: #dce2ea;
$gray-200: #e7e7ea;
$gray-100: #f3f3f5;
$gray-90: #f9f9f9;
$gray-80: #fcfcfc;
$gray-010: #f7f7f7;
$gray-000: #ffffff;

$state-success-700: #008a44;
$state-success-600: #20d077;
$state-success-500: #8fe8bd;
$state-success-400: #d2f7e5;
$state-success-200: #e2f6ed;

$state-warning-700: #c66b00;
$state-warning-600: #f3881b;
$state-warning-500: #f7b86e;
$state-warning-400: #fbdcb6;
$state-warning-200: #ffe5d6;

$state-informative-100: #e0e7ff;
$state-informative-600: #2d52d6;

$state-error-700: #c60027;
$state-error-600: #e24362;
$state-error-500: #efa2b0;
$state-error-400: #f9d9e0;
$state-error-300: #ffc7cd;
$state-error-200: #ffdce4;
$state-error-100: #ffeff3;

$informative-900: #001459;
$informative-800: #00239f;
$informative-700: #0d39d4;
$informative-600: #2d52d6;
$informative-500: #456bf0;
$informative-400: #5e81fd;
$informative-300: #829dff;
$informative-200: #b2c3ff;
$informative-100: #e0e7ff;
$informative-90: #eff2ff;
$informative-80: #fafbfe;

$success-900: #04381e;
$success-800: #0d6639;
$success-700: #008a44;
$success-600: #10b461;
$success-500: #4cc889;
$success-400: #8fe8bd;
$success-300: #d2f7e5;
$success-200: #e2f6ed;
$success-100: #e8fff4;

:export {
  primary900: $primary-900;
  primary800: $primary-800;
  primary700: $primary-700;
  primary600: $primary-600;
  primary500: $primary-500;
  primary400: $primary-400;
  primary300: $primary-300;
  primary200: $primary-200;
  primary100: $primary-100;

  accent900: $accent-900;
  accent800: $accent-800;
  accent700: $accent-700;
  accent600: $accent-600;
  accent500: $accent-500;
  accent400: $accent-400;
  accent300: $accent-300;
  accent200: $accent-200;
  accent100: $accent-100;

  gray900: $gray-900;
  gray800: $gray-800;
  gray700: $gray-700;
  gray600: $gray-600;
  gray500: $gray-500;
  gray400: $gray-400;
  gray300: $gray-300;
  gray200: $gray-200;
  gray100: $gray-100;
  gray90: $gray-90;
  gray80: $gray-80;
  gray000: $gray-000;

  stateSuccess700: $state-success-700;
  stateSuccess600: $state-success-600;
  stateSuccess500: $state-success-500;
  stateSuccess400: $state-success-400;
  stateSuccess200: $state-success-200;

  stateWarning700: $state-warning-700;
  stateWarning600: $state-warning-600;
  stateWarning500: $state-warning-500;
  stateWarning400: $state-warning-400;
  stateWarning200: $state-warning-200;

  destructive700: $state-error-700;
  stateError600: $state-error-600;
  stateError500: $state-error-500;
  stateError400: $state-error-400;
  stateError300: $state-error-300;
  destructive200: $state-error-200;
  destructive100: $state-error-100;

  informative900: $informative-900;
  informative800: $informative-800;
  informative700: $informative-700;
  informative600: $informative-600;
  informative500: $informative-500;
  informative400: $informative-400;
  informative300: $informative-300;
  informative200: $informative-200;
  informative100: $informative-100;
  informative90: $informative-90;
  informative80: $informative-80;
  success900: $success-900;
  success800: $success-800;
  success700: $success-700;
  success600: $success-600;
  success500: $success-500;
  success400: $success-400;
  success300: $success-300;
  success200: $success-200;
  success100: $success-100;
}
