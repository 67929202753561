@import '../../assets/styles/variables.scss';

.projectModalDiv {
  display: flex;
  align-items: flex-end;
}

.projectModalName {
  width: 130px;
}

.assignCompanyDiv {
  margin-top: 20px !important;
  width: 100%;
}

.assignCompanyDiv label {
  font-size: 18px;
  width: 200px;
}
