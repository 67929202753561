@import "../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../assets/styles/settings/fonts.module.scss";

$container-width: 463px;

.root {
  /* Positioning */
  position: fixed;
  top: 0;
  right: -#{$container-width};
  bottom: 0;
  z-index: 1200;

  /* Box-model */
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 27px 58px 27px 40px;
  width: $container-width;

  /* Visual */
  background-color: $gray-000;
  border-radius: 10px 0px 0px 0px;

  /* Misc */
  transition: right 0.6s cubic-bezier(0.19, 1.0, 0.22, 1.0);

  h1 {
    /* Box-model */
    margin-top: 0;
    margin-bottom: 22px;

    /* Typography */
    font-weight: 700;
  }

  h2 {
    /* Box-model */
    margin-top: 0;
    margin-bottom: 10px;

    /* Typography */
    color: $gray-900;
  }

}

.isOpen {
  /* Positioning */
  right: 0;
}

.button {
  /* Typography */
  color: $primary-600;

  &:hover {
    /* Visual */
    background-color: #{$primary-500}17;
  }

  &:focus {
    /* Visual */
    background-color: $primary-300;
    outline: none;
  }

  &:active {
    /* Typography */
    color: $primary-800;
    background-color: initial;
  }
}

.buttonExit {
  /* Positioning */
  position: absolute;
  top: 16px;
  right: 20px;
}

.buttonAdd {
  /* Box-model */
  display: flex;
  padding: 13px 15px;
  width: 100%;

  /* Flexbox Container */
  justify-content: flex-start;
  
  /* Typography */
  font-family: $body2-font-family;
  font-size: $body2-font-size;
  font-weight: 500;
  letter-spacing: $body2-letter-spacing;
  line-height: 1rem;
  text-transform: capitalize;

  /* Visual */
  border: 1px dashed $primary-600;
}

.iconAdd {
  /* Box-model */
  margin-left: 8px;
}

.fieldsList {
  /* Box-model */
  overflow-y: auto;
  margin-top: 30px;
  margin-bottom: 0;
  padding: 0;

  /* Visual */
  list-style: none;

  & > li + li {
    /* Box-model */
    margin-top: 8px;
  }
}
