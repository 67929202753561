@import "../../../../../assets/styles/settings/colors.module.scss";

.headerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.titleAndSelectorContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.titleAndHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  height: 36px;
  color: #232e39;
  font-family: "Aktiv Grotesk Corp";
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 37px;
  padding-top: 30px;
}

.typeSelectorContainer {
  width: 200px;
  margin-top: 20px;
}
