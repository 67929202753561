@import '../../styles/settings.scss';

.root {
  /* Box-model */
  display: flex;
  height: 0rem;

  /* Flexbox Container */
  flex-direction: column;

  /* Flexbox Item */
  flex: auto;
}

.onboarding {
  /* Box-model */
  margin: 0 $stage-view-gutter 0.75rem;
}
