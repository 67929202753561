@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/fonts.module.scss';

.root {
  /* Box-model */
  padding: 2.5rem 2.5rem 0.5rem 2.375rem;

  /* Visual */
  background-color: $gray-000;
}

.tabButtons {
  /* Box-model */
  display: flex;

  /* Flex container */
  justify-content: center;
}

.bottomContent {
  /* Box-model */
  display: flex;
  margin-top: 2rem;

  /* Flexbox Container */
  justify-content: space-between;
  align-items: flex-start;
}

button.detailButton {
  [class~='MuiButton-endIcon'] {
    /* Box-model */
    margin-left: 0.25rem;
  }
}
