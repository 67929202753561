$purple: #A80072;

.image-gallery {
  height: 55vh;
  width: calc(600px - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;

  .image-gallery-content {
    display: flex;
    flex-direction: column;
    .image-gallery-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 40vh;
      .image-gallery-image {
        max-height: 40vh;
      }
    }

    .image-gallery-thumbnails {
      .image-gallery-thumbnails-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        .image-gallery-thumbnail {
          height: auto;
        }
      }
    }

    &.fullscreen {
      .image-gallery-slide {
        height: 80vh;
        .image-gallery-image {
          max-height: 80vh;
        }
      }

      .image-gallery-thumbnails-wrapper {
        height: auto;
      }
    }
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 30px;
  }

  .image-gallery-fullscreen-button {
    .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border-color: $purple;
  }

  .image-gallery-icon:hover {
    color: $purple;
  }

  .image-gallery-icon:focus {
    outline: none;
  }
}