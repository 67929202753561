.Container {
  display: grid;
  grid-template-columns: 250px 200px;
  grid-template-rows: 1fr 1fr;
}

.Item {
  align-self: center;
}

.Deadline {
  justify-self: center
}
