@mixin AllTextFields {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55rem;
}

.TextField {
  width: 60%;
  height: 4.5rem;
}

.Enabled {
  @include AllTextFields;
  height: auto;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

.Disabled {
  @include AllTextFields;
  height: 0;
  animation: slideUp 1s ease 2;
  animation-direction: alternate;
}

@keyframes slideDown {
  0% {
    height: 0px;
  }

  100% {
    height: 15em;
  }
}

@keyframes slideUp {
  0% {
    max-height: 15em;
  }

  100% {
    max-height: 0px;
  }
}
