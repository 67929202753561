@import '../../../../assets/styles/settings/colors.module.scss';

.mainContainer {
  display: flex;
  margin-top: 22px;
}

.leftContainer {
  padding: 37px 45px;
  width: 57%;
  margin-right: 21px;
  align-items: center;

  display: flex;

  .left {
    display: flex;
    flex-direction: column;
  }

  .verticalSeparator {
    height: 100%;
    width: 0px;
    border: 1px solid $gray-300;
    margin-left: auto;
    margin-right: 50px;
  }

  .right {
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }
}

.rightContainer {
  flex: 1 0 auto;
  padding: 22px 45px;

  display: flex;
  flex-direction: column;

  .upon {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    .typographyContainer {
      display: flex;
      flex-direction: row;
      margin-top: 15px;

      .typographyItem {
        width: 50%;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;

    .typographyContainer {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-bottom: 15px;
    }
  }

  .horizontalSeparator {
    height: 0px;
    width: 100%;
    border: 1px solid $gray-300;
    margin-left: auto;
    margin-right: 50px;
  }
}

.withBottomSpace {
  margin-bottom: 24px;
}
