@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/settings/fonts.module.scss';

.generalTable {
  width: 1150px;
  height: 100%;
  overflow: auto;
}

.gridTable {
  height: 100%;
}

.fixed {
  background-color: $gray-100;
  z-index: 200;
  position: sticky;
  background-clip: padding-box;
}

.groupRow {
  background-color: $gray-000;
  font-weight: 500;
  padding: 0px 10px;
  height: 42px;
  border-right: 1px solid $gray-300;
}

.subGroupRow {
  background-color: $gray-90;
  height: 42px;
  padding: 0px 10px;
  color: $gray-700;
  border-right: 1px solid $gray-300;
}

.itemRow {
  background-color: $gray-100;
  height: 42px;
  padding: 0px 10px;
  color: $gray-700;
  border-right: 1px solid $gray-300;
}

.boldRows {
  color: $gray-900;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
}

.cashBalance {
  background-color: $success-200;
}

.builderCreditBalance {
  background-color: $informative-90;
}

.subBuilderCreditBalance {
  background-color: $informative-80;
}

.borderDarkGray {
  border-top: 2px solid darken($color: $gray-100, $amount: 10);
}

.editCell {
  position: relative;
  color: $primary-600;
  font-family: $headline-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.editCell::before {
  color: $primary-600;
  font-weight: 400;
  font-size: 0.7em;
  display: block;
  position: absolute;
  left: 4px;
  bottom: 2px;
  width: 80%;
  height: 15px;
}
