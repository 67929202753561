@import './colors.module.scss';

$headline-font-family: 'Roboto', sans-serif;
$body-font-family: 'Roboto', sans-serif;

$headline1-color: $gray-900;
$headline1-font-family: $headline-font-family;
$headline1-font-size: 2.875rem;
$headline1-font-style: normal;
$headline1-font-weight: 700;
$headline1-letter-spacing: 0;
$headline1-line-height: 3.375rem;

$headline2-color: $gray-900;
$headline2-font-family: $headline-font-family;
$headline2-font-size: 2.125rem;
$headline2-font-style: normal;
$headline2-font-weight: 700;
$headline2-letter-spacing: 0.03125rem;
$headline2-line-height: 2.625rem;

$headline3-color: $gray-900;
$headline3-font-family: $headline-font-family;
$headline3-font-size: 2rem;
$headline3-font-style: normal;
$headline3-font-weight: 700;
$headline3-letter-spacing: 0.03125rem;
$headline3-line-height: 2.5rem;

$headline4-color: $gray-900;
$headline4-font-family: $headline-font-family;
$headline4-font-size: 1.75rem;
$headline4-font-style: normal;
$headline4-font-weight: 500;
$headline4-letter-spacing: 0;
$headline4-line-height: 2.125rem;

$headline5-color: $gray-900;
$headline5-font-family: $headline-font-family;
$headline5-font-size: 1.5rem;
$headline5-font-style: normal;
$headline5-font-weight: 500;
$headline5-letter-spacing: 0;
$headline5-line-height: 1.875rem;

$headline6-color: $gray-900;
$headline6-font-family: $headline-font-family;
$headline6-font-size: 1.25rem;
$headline6-font-style: normal;
$headline6-font-weight: 500;
$headline6-letter-spacing: 0.03125rem;
$headline6-line-height: 1.5rem;

$subtitle1-color: $gray-800;
$subtitle1-font-family: $body-font-family;
$subtitle1-font-size: 1rem;
$subtitle1-font-style: normal;
$subtitle1-font-weight: 500;
$subtitle1-letter-spacing: 0.03125rem;
$subtitle1-line-height: 1.25rem;

$subtitle2-color: $gray-800;
$subtitle2-font-family: $body-font-family;
$subtitle2-font-size: 1rem;
$subtitle2-font-style: normal;
$subtitle2-font-weight: 500;
$subtitle2-letter-spacing: 0.03125rem;
$subtitle2-line-height: 1.125rem;

$body1-color: $gray-700;
$body1-font-family: $body-font-family;
$body1-font-size: 1rem;
$body1-font-style: normal;
$body1-font-weight: 400;
$body1-letter-spacing: 0;
$body1-line-height: 1.25rem;

$body2-color: $gray-700;
$body2-font-family: $body-font-family;
$body2-font-size: 0.875rem;
$body2-font-style: normal;
$body2-font-weight: 400;
$body2-letter-spacing: 0;
$body2-line-height: 1.125rem;

$caption-color: $gray-700;
$caption-font-family: $body-font-family;
$caption-font-size: 0.75rem;
$caption-font-style: normal;
$caption-font-weight: 300;
$caption-letter-spacing: 0.03125rem;
$caption-line-height: 1rem;

$button-medium-font-family: $body-font-family;
$button-medium-font-size: 1.125rem;
$button-medium-font-weight: 500;
$button-medium-letter-spacing: 0.03125rem;
$button-medium-line-height: 1.375rem;

$button-small-font-family: $body-font-family;
$button-small-font-size: 1rem;
$button-small-font-weight: 500;
$button-small-letter-spacing: 0.03125rem;
$button-small-line-height: 1.125rem;

:export {
  headline: $headline-font-family;
  body: $body-font-family;

  headline1-color: $headline1-color;
  headline1-font-family: $headline1-font-family;
  headline1-font-size: $headline1-font-size;
  headline1-font-style: $headline1-font-style;
  headline1-font-weight: $headline1-font-weight;
  headline1-letter-spacing: $headline1-letter-spacing;
  headline1-line-height: $headline1-line-height;

  headline2-color: $headline2-color;
  headline2-font-family: $headline2-font-family;
  headline2-font-size: $headline2-font-size;
  headline2-font-style: $headline2-font-style;
  headline2-font-weight: $headline2-font-weight;
  headline2-letter-spacing: $headline2-letter-spacing;
  headline2-line-height: $headline2-line-height;

  headline3-color: $headline3-color;
  headline3-font-family: $headline3-font-family;
  headline3-font-size: $headline3-font-size;
  headline3-font-style: $headline3-font-style;
  headline3-font-weight: $headline3-font-weight;
  headline3-letter-spacing: $headline3-letter-spacing;
  headline3-line-height: $headline3-line-height;

  headline4-color: $headline4-color;
  headline4-font-family: $headline4-font-family;
  headline4-font-size: $headline4-font-size;
  headline4-font-style: $headline4-font-style;
  headline4-font-weight: $headline4-font-weight;
  headline4-letter-spacing: $headline4-letter-spacing;
  headline4-line-height: $headline4-line-height;

  headline5-color: $headline5-color;
  headline5-font-family: $headline5-font-family;
  headline5-font-size: $headline5-font-size;
  headline5-font-style: $headline5-font-style;
  headline5-font-weight: $headline5-font-weight;
  headline5-letter-spacing: $headline5-letter-spacing;
  headline5-line-height: $headline5-line-height;

  headline6-color: $headline6-color;
  headline6-font-family: $headline6-font-family;
  headline6-font-size: $headline6-font-size;
  headline6-font-style: $headline6-font-style;
  headline6-font-weight: $headline6-font-weight;
  headline6-letter-spacing: $headline6-letter-spacing;
  headline6-line-height: $headline6-line-height;

  subtitle1-color: $subtitle1-color;
  subtitle1-font-family: $subtitle1-font-family;
  subtitle1-font-size: $subtitle1-font-size;
  subtitle1-font-style: $subtitle1-font-style;
  subtitle1-font-weight: $subtitle1-font-weight;
  subtitle1-letter-spacing: $subtitle1-letter-spacing;
  subtitle1-line-height: $subtitle1-line-height;

  subtitle2-color: $subtitle2-color;
  subtitle2-font-family: $subtitle2-font-family;
  subtitle2-font-size: $subtitle2-font-size;
  subtitle2-font-style: $subtitle2-font-style;
  subtitle2-font-weight: $subtitle2-font-weight;
  subtitle2-letter-spacing: $subtitle2-letter-spacing;
  subtitle2-line-height: $subtitle2-line-height;

  body1-color: $body1-color;
  body1-font-family: $body1-font-family;
  body1-font-size: $body1-font-size;
  body1-font-style: $body1-font-style;
  body1-font-weight: $body1-font-weight;
  body1-letter-spacing: $body1-letter-spacing;
  body1-line-height: $body1-line-height;

  body2-color: $body2-color;
  body2-font-family: $body2-font-family;
  body2-font-size: $body2-font-size;
  body2-font-style: $body2-font-style;
  body2-font-weight: $body2-font-weight;
  body2-letter-spacing: $body2-letter-spacing;
  body2-line-height: $body2-line-height;

  caption-color: $caption-color;
  caption-font-family: $caption-font-family;
  caption-font-size: $caption-font-size;
  caption-font-style: $caption-font-style;
  caption-font-weight: $caption-font-weight;
  caption-letter-spacing: $caption-letter-spacing;
  caption-line-height: $caption-line-height;

  button-medium-font-family: $button-medium-font-family;
  button-medium-font-size: $button-medium-font-size;
  button-medium-font-weight: $button-medium-font-weight;
  button-medium-letter-spacing: $button-medium-letter-spacing;
  button-medium-line-height: $button-medium-line-height;

  button-small-font-family: $button-small-font-family;
  button-small-font-size: $button-small-font-size;
  button-small-font-weight: $button-small-font-weight;
  button-small-letter-spacing: $button-small-letter-spacing;
  button-small-line-height: $button-small-line-height;
}
