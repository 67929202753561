.priceContainer {
  display: flex;

  .container {
    border-radius: 4px;
    margin: 15px 10px;
    padding: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
    box-sizing: border-box;
    /* Basic styling and alignment */
    /* For Neumorphism Effect */
    background-color: #ffffff;
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
      -9px -9px 16px rgba(255, 255, 255, 0.5);
    /* For Neumorphism Effect */

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 26px;
      display: block;
      margin-bottom: 25px;
    }

    .content {
      flex-grow: 1;

      .stat {
        display: flex;
        .label {
          flex-grow: 1;
          font-weight: 700;
        }
      }
    }
  }
}
