@import '../../../../../assets/styles/settings/colors.module.scss';

.Button {
  background-color: rgba($primary-600, 0.1);
  font-weight: 600;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  color: $primary-600;
  text-transform: lowercase;

  &:focus,
  & *:focus {
    outline: none;
  }

  & *::first-letter {
    text-transform: uppercase;
  }
}

.Transparent {
  background-color: transparent;
}
