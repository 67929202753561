@import '../../../../assets/styles/settings/colors.module.scss';

.button {
  padding: 0.5em 2em 0.5em 2em;
  border: 1px solid $primary-600;
  color: $primary-600;
  min-width: 100px;
  text-transform: capitalize;

  &[class*='disabled'] {
    border: 1px solid lightgray;
  }
}

.buttonInitial {
  @extend .button;
  border: 1px solid lightgray;
}

.buttonOutline {
  @extend .button;
  border: 1px solid $primary-600;
  color: $primary-600;
}

.buttonSimple {
  @extend .button;
  border: 0;
  background: rgba($primary-600, 0.1);
  color: $primary-600;
}

.buttonEnd {
  @extend .button;
  background: $primary-600;
  color: white;
  &:hover {
    background: darken($primary-600, 4%);
    color: white;
  }
}
