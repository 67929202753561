@import '../../../../assets/styles/variables.scss';

$width: 25px;
$height: 25px;

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Label {
  font-size: 16px;
  margin-right: 14px;
}

.GreenHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-green-color;
}
.YellowHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $secondary-color;
}
.BlueHelper {
  margin-left: 30% !important;
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-color;
}

.ContainerSquare {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
}
.GreenHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-green-color;
}
.YellowHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $secondary-color;
}
.BlueHelperSquare {
  width: $width;
  height: $height;
  margin-right: 4px;
  background-color: $main-color;
}

.noAreasSelected {
  color: $gray-color;
}
