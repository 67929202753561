.root {
  /* Box-model */
  padding: 0 1.5rem;
}

.titleContainer {
  /* Box-model */
  display: flex;
  margin-bottom: 0.5rem;

  /* Flexbox container */
  align-items: center;

  & > :first-child {
    /* Box-model */
    margin: 0 0.5rem 0 0;
  }

  & > :last-child {
    /* Typography */
    font-style: italic;
    font-weight: 400;
  }

  &.isHighlighted {
    & > :first-child {
      /* Typography */
      font-weight: 700;
    }
  }
}

.datesContainer {
  /* Box-model */
  display: flex;

  & > :last-child {
    /* Box-model */
    margin-left: 0.5rem;
  }
}
