@import '../../../../../assets/styles/settings/colors.module.scss';

$item-padding-right: 2px;
$item-padding-left: 8px;
$border-size: 1px;
$border-size--hover: 2px;

.root {
  /* Box-model */
  display: flex;
  height: 46px;

  /* Flexbox Container */
  align-items: center;

  &.isGrabbing {
    .actions {
      /* Visual */
      background-color: $gray-100;
    }

    .dragIcon {
      /* Typography */
      color: $primary-600;
    }

    .visibility {
      /* Visual */
      visibility: hidden;
    }
  }
}

.actions {
  /* Box-model */
  display: flex;
  padding: 0 $item-padding-right 0 $item-padding-left;
  height: 100%;

  /* Flexbox Container */
  align-items: center;

  /* Flexbox item */
  flex: auto;

  /* Visual */
  border: $border-size solid rgba(105, 133, 161, 0.24);
  border-radius: 6px;

  &:hover {
    /* Box-model */
    padding-right: #{$item-padding-right - ($border-size--hover - $border-size)};
    padding-left: #{$item-padding-left - ($border-size--hover - $border-size)};

    /* Visual */
    border: $border-size--hover solid $primary-600;
  }
}

.dragIcon {
  $icon-size: 24px;

  /* Box-model */
  margin-right: 4px;
  width: $icon-size;
  height: $icon-size;

  /* Typography */
  color: $gray-600;

  &:hover {
    /* Visual */
    cursor: grab;
  }
}

.label {
  /* Flexbox item */
  flex: auto;
}

.more {
  /* Box-model */
  padding: 6px;
}

.menu {
  .deleteItem {
    /* Typography */
    color: $state-error-600;
  }
}

.visibility {
  /* Box-model */
  margin-left: 1px;
}
