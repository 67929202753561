@import '../../../../assets/styles/components/table.module.scss';
@import '../../../../assets/styles/settings/fonts.module.scss';

.fakeComponent {
  /* Positioning */
  position: absolute;
  top: 0;
  left: 0;

  /* Box-model */
  overflow: hidden;
  padding: $cell-padding;
  padding-left: 52px;
  width: 100%;
  height: 100%;

  /* Typography */
  color: $gray-900;
  font-family: $headline-font-family;
  font-weight: 400;
  text-overflow: ellipsis;
}

.icon {
  /* Positioning */
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &:focus,
  &:hover,
  &:active {
    /* Visual */
    outline: none;
  }
}
