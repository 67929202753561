@import '../../../assets/styles/variables.scss';

.Button {
  text-decoration: none;
  color: $white-color;
  background: $main-color;
  font-size: 12px;
  border-radius: 4px;
  padding: 10px 40px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  margin: 0 10px;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background: #ccc;
    cursor: default;
  }
}
