@import '../../../../../assets/styles/settings/colors.module.scss';

.EvalproDeedExpensesCellNumber input {
  text-align: center;
  background: rgba(0, 163, 169, 0.1);
  border-radius: 5px;
}

.isStock {
  color: $primary-600;
}
