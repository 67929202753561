@import "../../../../assets/styles/settings/colors.module.scss";

.titleAndSelectorContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e4;
  margin-bottom: 20px;
}

.titleAndHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  height: 36px;
  color: #232e39;
  font-family: "Aktiv Grotesk Corp";
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 37px;
  padding-top: 30px;
}

.headerOptions {
  display: flex;
  margin-top: 80px;
  margin-bottom: 15px;
  align-items: flex-end;
  justify-content: space-between;
}

.typeSelectorContainer {
  width: 200px;
  margin-top: 15px;
}

.reportButtonContainer {
  display: flex;
  width: 10%;
  align-items: flex-end;
  margin-left: 86%;
  margin-bottom: 20px;
}
