
@import '../../../../assets/styles/settings/colors.module.scss';
@import '../../../../assets/styles/settings/fonts.module.scss';

.bgColorSecondary {
  background-color: $primary-400 !important;
}

.amount {
  font-size: $body2-font-size;
  letter-spacing: 0.3;
  margin-left: auto;
  padding-left: 16;
}

.toolbar {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
}

.buttonMenu {
  max-width: 400px;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.menuItem {
  background-color: $gray-000;
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-100;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-100 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-100;
}

.noPadding {
  padding: 0 !important;
}