.status {
  margin-bottom: 25px;
}

.title {
  font-weight: 700;
  margin-right: 45%;
  flex-grow: 1;
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.inputContainer {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .row {
    flex-grow: 1;
    display: flex;
    margin-right: 15px;
  }

  .label {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .input {
    text-align: end;
  }
}

.coOwnerContainer {
  display: flex;
  flex-direction: row;
}

.coOwnerName {
  margin-right: 2%;
}

.dividedInputContainer {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid black;

  .row {
    flex-grow: 1;
    margin-right: 15px;
  }

  .label {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .input {
    text-align: end;
  }
}

.DateTimePicker {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}
