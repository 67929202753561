@import "../../../assets/styles/settings/colors.module.scss";
@import "../../../assets/styles/components/modal.module.scss";

.root {
  /* Positioning */
  position: relative;

  /* Box-model */
  padding: 2.25rem $modal-gutter 1.5rem;

  h2 {
    /* Box-model */
    margin: 0;

    /* Typography */
    color: $gray-800;
    font-weight: 700;
  }
}

.closeButton {
  /* Positioning */
  position: absolute;
  top: 2rem;
  right: 2rem;
}
