@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  display: flex;

  /* Flexbox container */
  align-items: center;

  /* Typography */
  color: $gray-900;
}

.goBackButton {
  /* Box-model */
  margin-right: 0.25rem;

  /* Typography */
  color: inherit;
}

h1.title {
  /* Box-model */
  margin: 0;

  /* Typography */
  color: inherit;
  line-height: 2.125rem;
}

.space {
  /* Flexbox Item */
  flex: auto;
}

button.addStageButton {
  /* Box-model */
  margin-right: 0.1875rem;
}

div.addStageIcon {
  /* Box-model */
  margin-right: 0.25rem;
}
