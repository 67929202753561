@import '../../../../../assets/styles/settings/colors.module.scss';

.modal {
  /* Box-model */
  width: 34.875rem;
}

.content {
  /* Box-model */
  padding: 1.75rem 6rem 2.6875rem;
}

.nameField {
  /* Box-model */
  width: 100%;
}

.descriptionField {
  /* Box-model */
  margin-top: 1.375rem;
  width: 100%;
}

button.saveButton {
  /* Positioning */
  position: relative;
}

.loadingProgress {
  /* Positioning */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Box-model */
  height: 30px;
}

.circularProgress {
  /* Typography */
  color: $primary-600;
}
