@import '../../../assets/styles/settings/colors.module.scss';

* {
  box-sizing: border-box;
}

.Button {
  border-radius: 8px;
  border: 2px solid $primary-600;
  text-transform: lowercase;
  padding: 0 2rem 0 2rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & *::first-letter {
    text-transform: uppercase;
  }
}

.secondary {
  @extend .Button;
  margin-right: 10px;
  color: $primary-600;
}

.primary {
  background-color: $primary-600;
  color: $gray-000;
  &:hover {
    background-color: lighten($primary-700, 10%);
  }
}
