@import '../../../../../assets/styles/variables.scss';

.Absolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 104px;
}
​ .Badge {
  margin-left: 5px;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: $white-color;
  overflow: hidden;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.2s;
  box-shadow: 0 2px 5px 0 $black-color;
  background: $main-color;
  top: 4px;
  cursor: pointer;
}
