.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .xs {
    line-height: 1;
    font-size: 0.75rem;
  }
}
