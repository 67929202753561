@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/components/table.module.scss';

$tag-cell-padding: 0 #{$cell-width-padding + 27px} 0 $cell-width-padding;
$tag-cell-padding--hover: 0 #{$cell-width-padding + 26px} 0 #{$cell-width-padding -
  1px};
$cell-border: 1px solid $gray-300;

.root {
  /* Visual */
  outline: none;

  &:focus {
    /* Visual */
    outline: none;
  }

  .button {
    /* Positioning */
    position: relative;

    /* Box-model */
    display: block;
    margin: 0;
    padding: $tag-cell-padding;
    width: 100%;
    height: 42px;

    /* Visual */
    border-radius: 0;

    /* Misc */
    transition: none;

    &:hover,
    &.isOpen {
      /* Box-model */
      padding: $tag-cell-padding--hover;

      /* Visual */
      background-color: transparent;
      border-right: $cell-border;
      border-left: $cell-border;

      .icon {
        /* Box-model */
        display: block;
      }
    }

    &:focus {
      /* Visual */
      outline: none;
    }
  }

  .icon {
    /* Positioning */
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translateY(-50%);

    /* Box-model */
    display: none;
  }
}

.tagContainer {
  /* Box-model */
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;

  /* Flex Container */
  align-items: center;

  & > * {
    /* Box-model */
    max-width: 100%;
  }

  & > * + * {
    /* Box-model */
    margin-left: 6px;
  }

  &:empty {
    &::after {
      /* Box-model */
      content: '';
      width: 14px;
      height: 2px;

      /* Visual */
      background-color: $gray-600;
    }
  }
}
