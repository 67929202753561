@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.leftHeader {
  height: 40px !important;
  background-color: $gray-000;
  color: $gray-700;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0%;
  margin: 0%;
  border-right: solid 1px $gray-300;
  padding-top: 55px;
  padding-left: 0%;
  position: sticky;
  border-top: solid 1px $gray-300;
}

.leftHeader:first-child {
  padding-left: 0px;
}

.leftHeaderTypography {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: $primary-100;
  color: $gray-700;
  margin: 0%;
  font-family: $headline-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  bottom: 0%;
  left: 0%;
  padding-left: 0%;
  border-top: solid 1px $gray-300;
  border-left: 1px solid $gray-300;
}

.leftHeaderTypography:first-child {
  padding-left: 70px;
}

.headerCell {
  height: 40px;
  background-color: $primary-100;
  color: $gray-700;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0%;
  margin: 0%;
  border: solid 1px $gray-300;
}

.projectedHeaderCell {
  height: 40px;
  background-color: $accent-600;
  color: $gray-000;
  font-family: $headline-font-family;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0%;
  margin: 0%;
  border: solid 1px $gray-300;
}

.headerTypography {
  height: 30px;
  width: 108px;
  color: $gray-700;
  margin: 0%;
  padding: 6px 0px 0px 8px;
  font-family: $headline-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.projectedHeaderTypography {
  height: 30px;
  width: 108px;
  color: $gray-000;
  margin: 0%;
  padding: 6px 0px 0px 8px;
  font-family: $headline-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.projectCell {
  display: flex;
}
