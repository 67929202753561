@import '../../../../../../assets/styles/settings/colors.module.scss';

.verticalLine {
  position: absolute;
  background-color: $primary-600;
  width: 2px;
  height: calc(100%);
  left: 28px;
  top: 0;
}
