@import '../../../../assets/styles/settings/colors.module.scss';
@import '../../../../assets/styles/settings/fonts.module.scss';
@import '../../../../assets/styles/components/table.module.scss';

.tableRow {
  padding: 11px 11px 11px $cell-width-padding;
  color: $gray-700;
  font-family: $body-font-family;
  font-size: $body1-font-size;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  background: $primary-100;
  border-bottom: 2px solid rgba(105, 133, 161, 0.24);
}
