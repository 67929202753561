.Input {
  width: 90%;
  font-size: 14px;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 25% 15%;
  column-gap: 1%;
}

.Loader {
  position: sticky;
  top: 0;
  height: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}
