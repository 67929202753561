@import "../../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../../assets/styles/settings/fonts.module.scss";

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $primary-900;
  width: 210px;
  height: 123px;
  background-color: $gray-000;
  border: 3px solid $gray-200;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.text {
  height: 50px;
  left: 15.24%;
  right: 40%;
  top: calc(50% - 50px / 2 + 8px);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.utilityTypography {
  color: $gray-900;
  font-style: normal;
  font-family: $subtitle1-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding: 0%;
}

.withoutQuote {
  color: red;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.groupTypography {
  color: $gray-800;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.itemsTypography {
  color: $gray-700;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.contractsTypography {
  color: $gray-700;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

.expensesProfits {
  color: $success-700;
}

.expensesLosses {
  color: $state-error-700;
}

.incomeProfits {
  color: $success-700;
}

.incomeLosses {
  color: $state-error-700;
}

.marginCell {
  padding-left: 10px;
}
