@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../styles/DeedExpenses.modules.scss';

.EvalproDeedExpensesMainContainer {
  position: relative;
  display: flex;
  min-height: 130px;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: calc(100% - #{$margin-content});
  margin: 0 $deed-expenses-margin $deed-expenses-margin $deed-expenses-margin;
  padding: 60px $deed-expenses-padding 0 $deed-expenses-padding;
  background: $gray-000;
}

.Loading {
  position: absolute;
  top: 40px;
  right: 40px;
}
