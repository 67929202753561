@import '../../../../../assets/styles/settings/colors.module.scss';

.root {
  /* Box-model */
  display: flex;
  padding-top: 3.5rem;

  /* Flexbox Container */
  flex-direction: column;
  align-items: center;
}

.message {
  /* Box-model */
  padding-bottom: 1.5rem;

  /* Typography */
  color: $gray-700;
}

.button {
  /* Typography */
  text-transform: none;
}
