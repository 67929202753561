@import "../../../assets/styles/variables.scss";

.Container {
  display: flex;
}

.Cell {
  padding: 8px;
  margin: 5px;
  border: 1px solid $softGrayBackground-color;
  border-radius: 7px;
  min-width: 50px;
  min-height: 22px;
  text-align: center;
  cursor: pointer;
  /* &:hover {
    background: lighten($main-color, 35);
    color: white
  } */
  &:hover {
    background: lighten($green-color,10%);
    box-shadow: 0 4px 17px rgba(0,0,0,0.2);
    transform: translate3d(0, -2px, 0);
    color: white
  }
  &:active {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
    transform: translate3d(0, 1px, 0);
  }
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}