@import '../../../../../assets/styles/settings/colors.module.scss';

.LogoContainer {
  border-radius: 10px;
  max-width: 80%;
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Container {
  position: relative;
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.Logo {
  width: 100%;
  padding: 10px;
}

.UpLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed $primary-600;
  padding: 40px;
  cursor: pointer;
  margin: 16px 0;
}
