@import '../../../assets/styles/settings/colors.module.scss';
@import '../../../assets/styles/tools/colorFunctions.module.scss';

.container {
  background: $gray-000;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0px 1px 6px hex-alpha($gray-500, 0.4);
  border-radius: 10px;
}
