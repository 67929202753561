@import '../../../assets/styles/settings/colors.module.scss';

.Header {
  max-height: 70px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-100;
  color: $gray-900;
  border-bottom: 2px solid rgba(105, 133, 161, 0.24);
}

.CloseButton {
  color: $primary-600;
  font-size: 12px;
  padding: 4px;
  & svg {
    width: 20px;
    height: 20px;
  }
}

.drawer {
  & *:focus {
    outline: none;
  }
}

.container {
  position: relative;
  height: 100%;
  min-width: 510px;
  max-width: 510px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
