.container {
  margin: 10% 0 0 0;
  width: 150px;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(1, 1fr) !important;
}

.slider{
  color: #00D0D4;
}