@import "../../../../../assets/styles/settings/colors.module.scss";

.root {
  /* Box-model */
  display: flex;
  margin-left: 5px;
  width: 100%;
  height: 24px;

  /* Flex Container */
  align-items: center;

  &::before {
    /* Box-model */
    content: "";
    width: 14px;
    height: 2px;
    
    /* Visual */
    background-color: $gray-600;
  }

  &.isSelected {
    &::before {
      /* Visual */
      background-color: $gray-000;
    }
  }
}
