@import "../../../assets/styles/variables.scss";
.Container {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  font-size: 14px;
}

.Card {
  flex: 1;
  background: $softVariantGrayBackground-color;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1);
  padding: 10px;
  margin: 10px;
  min-width: 347.234px;
  border-radius: 8px;
  flex-wrap: nowrap;
  p {
    margin: 0px 85px 10px 25px;
  }

  h3 {
    margin: 0px 10px 10px 25px;
  }
}