@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/tools/colorFunctions.module.scss';
@import '../../styles/settings.scss';

.root {
  /* Positioning */
  position: relative;

  /* Box-model */
  padding: 1rem 2rem 1.25rem 3.25rem;
  height: $tower-card-height;

  /* Visual */
  background-color: $gray-000;
  border-radius: 0.25rem;
  filter: drop-shadow(0 0.0625rem 0.375rem rgba(161, 171, 181, 0.4));

  &[draggable='true'] {
    &:hover {
      /* Visual */
      cursor: pointer;

      .dragIcon {
        /* Typography */
        color: $gray-600;
      }
    }

    &.isGrabbing {
      .dragIcon {
        /* Typography */
        color: $primary-600;
      }
    }
  }

  &[draggable='false'] {
    /* Box-model */
    padding-left: 1.5rem;

    .dragIcon {
      /* Box-model */
      display: none;
    }
  }

  &.isDropZone {
    &::before,
    &::after {
      /* Positioning */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;

      /* Box-model */
      content: '';
    }

    &::before {
      /* Visual */
      background-color: $gray-000;
    }

    &::after {
      /* Visual */
      background-color: hex-alpha($primary-600);
      border: 0.125rem solid $primary-600;
    }
  }

  &.isGrabbing {
    .dragIcon {
      /* Typography */
      color: $primary-600;
    }
  }

  &.isDropZone {
    &::before,
    &::after {
      /* Positioning */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;

      /* Box-model */
      content: '';
    }

    &::before {
      /* Visual */
      background-color: $gray-000;
    }

    &::after {
      /* Visual */
      background-color: hex-alpha($primary-600);
      border: 0.125rem solid $primary-600;
    }
  }
}

.dragIcon {
  $icon-size: 24px;

  /* Positioning */
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);

  /* Box-model */
  width: $icon-size;
  height: $icon-size;

  /* Typography */
  color: $gray-500;

  &:hover {
    /* Visual */
    cursor: grab;
  }
}

h3.name {
  /* Box-model */
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0.25rem;

  /* Typography */
  white-space: nowrap;
  text-overflow: ellipsis;
}

p.description {
  /* Box-model */
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &:empty {
    /* Typography */
    color: $gray-400;

    &::before {
      /* Box-model */
      content: 'Sin descripción';
    }
  }
}

.moreIcon {
  /* Positioning */
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  &.isOpen {
    /* Typography */
    color: $primary-600;
  }
}

li.deleteItem {
  /* Typography */
  color: $state-error-600;
}
