@import '../../../../assets/styles/variables';

.container {
  padding: 1% !important;
  margin-bottom: 0.5%;
}

.grid {
  width: 95% !important;
  margin-left: 1% !important;
}

.total {
  background-color: inherit !important;
  color: $main-color !important;
  padding: 2% 5% !important;
  width: 100% !important;
  border-radius: 2% !important;
}

.itemContainer {
  margin-right: 3% !important;
}

.readjustment {
  background-color: inherit !important;
  color: $main-color !important;
  padding: 2% 5% !important;
  width: 100% !important;
  border-radius: 2% !important;
}

.totalText {
  clear: both !important;
  display: inline-block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.totalFn {
  background-color: inherit !important;
  color: $main-color !important;
  padding: 2% 5% !important;
  width: 100% !important;
  border-radius: 2% !important;
}