@import '../../../../../assets/styles/settings/colors.module.scss';

.DeedExpensesInformationLabel {
  position: relative;
  min-width: 350px;
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 30px;
}

.Icon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-55%);
}
.Label {
  margin-right: 8px;
}
