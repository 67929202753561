@import '../../../../assets/styles/settings/colors.module.scss';

.Container {
  background-color: $gray-000;
  border-radius: 6px;
  border: 1px solid $gray-300;
  border-top: 2px solid $gray-200;
  border-bottom: 0;
  max-width: 500px;
}
