@import '../../../assets/styles/settings/colors.module.scss';
@import '../../../assets/styles/tools/colorFunctions.module.scss';

$button-color: $accent-600;

.root {
  /* Box-model */
  display: flex;
  padding: 1.4375rem 0 1.1875rem 0;

  /* Typography */
  justify-content: center;
}

button.controlButton {
  /* Box-model*/
  min-height: 2rem;

  /* Typography */
  color: $button-color;
  font-size: 0.875rem;
  line-height: 1rem;

  &[class~='MuiButton-outlined'] {
    /* Box-model */
    padding: 0.375rem 0.375rem;

    /* Visual */
    border-color: $button-color;

    &:hover {
      /* Typography */
      color: $accent-500;

      /* Visual */
      background-color: hex-alpha($accent-500, 0.1);
      border-color: $accent-500;
    }

    &:focus {
      /* Typography */
      color: $button-color;

      /* Visual */
      background-color: transparent;
      border-color: $button-color;
      outline: $accent-300 solid 0.25rem;
      outline-offset: 0;
    }

    &:active {
      /* Typography */
      color: $accent-700;

      /* Visual */
      background-color: transparent;
      border-color: $accent-700;
    }
  }

  &[class~='MuiButton-contained'] {
    /* Box-model */
    padding: 0.4375rem 0.4375rem;

    /* Typography */
    color: $gray-000;

    /* Visual */
    background-color: $button-color;

    &:hover {
      /* Typography */
      color: $gray-000;

      /* Visual */
      background-color: $accent-500;
    }

    &:focus {
      /* Typography */
      color: $gray-000;

      /* Visual */
      background-color: $accent-600;
      outline: $accent-300 solid 0.25rem;
      outline-offset: 0;
    }

    &:active {
      /* Typography */
      color: $gray-000;

      /* Visual */
      background-color: $accent-700;
    }
  }
}
