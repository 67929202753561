@import '../../../../../assets/styles/settings/colors.module.scss';

.QuotationQuestionTooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $primary-600;
  font-weight: 500;
  width: 20px;
  height: 20px;
  background-color: rgba($primary-600, 0.1);
  font-size: 14px;
  cursor: help;
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  margin: 0 10px;
}
