@import '../../../../assets/styles/settings/colors.module.scss';

.Underscore {
  width: 100%;
  height: 2px;
  background: $primary-600;
}

.NameText {
  color: $primary-600;
  text-align: center;
  padding: 15px 20px;
}

.Button {
  text-transform: none;
  display: flex;
  padding: 0px;
}
