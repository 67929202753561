.Skeleton {
  animation: SkeletonLoading 1s linear infinite alternate;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.Container {
  position: relative;
  min-height: 40vh;
}

@keyframes SkeletonLoading {
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
