@import "../../assets/styles/variables.scss";

.btnStyle {
  color: #fff !important;
  background-color: $main-color !important;
  margin: 2% !important;
}

.btnModal {
  color: $main-color !important;
}

.cardLeft {
  display: flex;
  flex-direction: column;
  width: 35vw;
}

.cardRight {
  display: flex;
  flex-direction: column;
  width: 35vw;
  margin-left: 3% !important;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.selectController {
  margin: 2% !important ;
}

.labelSelect {
  background-color: $defaultWhite-color;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.downList {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 5%;
}

.collapsable {
  margin: 2% !important;
  padding: -5% !important;
}

.projectList {
  margin: 1%;
}
.titleDashboard {
  margin-left: 3%;
}
.dialogContent {
  width: 92% !important;
  height: 30vh !important;
}

.texfieldModal {
  margin: 1% !important;
  margin-left: 7% !important;
}
