.NonIdealState {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  max-width: 329px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & * {
    text-align: center;
  }
}
