@import '../../../assets/styles/variables.scss';

.container {
  display: flex;
  padding: 15px;
  margin-bottom: 16px;
  color: #fff;
  border-radius: 4px;
  width: 15vw;

  .iconContent {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  .messageContainer {
    flex-grow: 1;

    .titleContainer {
      display: flex;

      .title {
        font-weight: bold;
        margin-bottom: 4px;
        font-size: 16px;
        flex-grow: 1;
      }

      .icon {
        cursor: pointer;
        font-size: 14px;
      }
    }

    .description {
      font-size: 14px;
      display: block;
    }
  }
}

.default {
  background-color: $gray-color;
}

.success {
  background-color: $softGreenColor;
}

.error {
  background-color: $red-color;
}

.info {
  background-color: $main-color;
}

.warn {
  background-color: $yellow-color;
}
