.GroupNameContainer {
  display: flex !important;
  flex-direction: row;
  width: 80% !important;
  align-items: center !important;
  justify-content: center !important;
}

.Tabpanelroot {
  width: 80% !important;
  align-items: center !important;
  margin-left: 18% !important;
}

.GroupTextField {
  font-size: 18px !important;
}
