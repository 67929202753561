@import '../../../../../assets/styles/variables.scss';

//*************** SETTINGS ***************//

.flexContainer {
  display: flex;
  align-items: center;
}

.tableRow {
  cursor: pointer;
}
.tableCell {
  flex: 1;
}
.noClick {
  cursor: initial;
}

.capitalizeAndBold {
  text-transform: capitalize;
  font-weight: bold;
}

.red {
  color: $red-color;
}

.green {
  color: $green-color;
}
