@import '../../../../assets/styles/elements/typography.module.scss';

.dlHorizontal {
dt {
  @extend .subtitle2;
  float: left;
  clear: left;
  width: 85px;
  text-align: left;
  font-size: 14px !important;
}
dt::after {
  content: ":";
}
dd {
  margin: 0 0 0 85px;
  padding: 2px 0 3px 0;
  font-size: 13px;
  font-weight: 300;
}
}
