.drawer {
  width: 80%;
  flex-shrink: 0;
  
}

.drawerPaper {
  width: 80%;
  overflow: auto;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

.title {
  flex-grow: 1;
}