@import '../../../../../assets/styles/settings/colors.module.scss';

$width: 50px;

.container {
  padding: 8px 5px;
  position: relative;
}

.verticalLine {
  position: absolute;
  background-color: $primary-600;
  width: 2px;
  height: calc(100%);
  left: 29px;
}

.AddColumnButton {
  & * {
    margin: 0;
    padding: 0;
  }

  width: $width;
  min-width: $width;
  max-width: $width;
  height: $width;
  min-height: $width;
  max-height: $width;
  padding: 0;
  margin-top: 0px;
}
