@import "../../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../../assets/styles/settings/fonts.module.scss";

.emptyCell {
  height: 42px;
  background: $gray-90;
  border: solid 1px $gray-300;
}

.bandCell {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $gray-700;
}
