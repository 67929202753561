@import '../../../../assets/styles/settings/colors.module.scss';

.container {
  position: relative;
  height: 100%;
  min-width: 510px;
  max-width: 510px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BoxCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ThreadContainer {
  padding: 24px;
  border-bottom: 1px solid $gray-300;
}

.ThreadInteractionContainer {
  position: relative;
  margin-bottom: 16px;
}

.ThreadNewInteractionContainer {
  padding-left: 20px;
}

.ThreadInteractionHeader {
  @extend .BoxCenter;
  width: 100%;
  margin-bottom: 8px;
}

.ThreadNewInteractionButton {
  font-weight: 500;
  color: $primary-600;
  text-transform: lowercase;
  & *::first-letter {
    text-transform: uppercase;
  }
}

.ThreadInteractionComment {
  padding: 16px;
  border-radius: 6px;
  background: $gray-010;
}

.ThreadInteractionCommentEdit {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  z-index: 1;
}

.ThreadInteractionFile {
  padding: 16px;
  background: $gray-000;
}

.ThreadInteractionFileAttachedContainer {
  @extend .BoxCenter;
  margin-top: 30px;
}

.ThreadInteractionFileAttached {
  @extend .BoxCenter;
  position: relative;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 14px;
  padding-left: 48px;
  border: 1px solid $gray-300;
  border-radius: 6px;
  cursor: pointer;
}

.ThreadInteractionFileAttachedIcon {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  color: $state-success-600;
}
