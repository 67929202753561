@import "../../../../assets/styles/settings/colors.module.scss";

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $primary-900;
  width: 210px;
  height: 123px;
  background-color: $gray-000;
  border: 3px solid $gray-200;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.boldRows {
  color: $gray-900;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
}

.text {
  height: 50px;
  left: 15.24%;
  right: 40%;
  top: calc(50% - 50px / 2 + 8px);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.values {
  height: 40px;
  left: 0%;
  right: 0%;
  top: calc(50% - 40px / 2 - 28px);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
}
