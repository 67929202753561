@import '../../../assets/styles/settings/colors.module.scss';

.sameWidthItem {
  flex: 1 1 0px;
}

.wholeWidth {
  width: 100%;
}

.saveButton {
  background: $primary-600;
  border-radius: 8px;
  color: $gray-000;
  text-transform: none;
}

.floatingLabelFocusStyle {
  color: $gray-500;
}
