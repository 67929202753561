.root {
  /* Positioning */
  position: relative;

  /* Box-model */
  display: inline-block;
}

button.button {
  /* Misc */
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus {
    /* Positioning */
    z-index: 1;
  }

  &:first-child {
    /* Positioning */
    transform: translateX(0.0625rem);

    /* Visual */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    /* Positioning */
    transform: translateX(-0.0625rem);

    /* Visual */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:first-child):not(:last-child) {
    /* Visual */
    border-radius: 0;
  }
}
