@import '../../../assets/styles/variables';

.add {
  color: $main-color;
  font-size: 1.5em !important;
}

.delete {
  color: $redButton-color;
}

.check {
  color: $main-color;
}

.edit {
  color: $main-color;
}

.clear {
  color: $redButton-color;
}
