@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/tools/colorFunctions.module.scss';

.root {
  /* Box-model */
  display: flex;

  /* Flexbox Container */
  justify-content: space-between;
  align-items: center;

  &.isAvailable {
    .label {
      /* Typography */
      color: $state-success-700;
    }
  }
}

.switch {
  [class~='MuiIconButton-root']:hover {
    /* Visual */
    background-color: hex-alpha($state-success-700, 0.3);
  }

  [class~='MuiSwitch-colorPrimary'] + [class~='MuiSwitch-track'] {
    /* Visual */
    background-color: $state-success-700;
  }

  [class~='MuiSwitch-colorPrimary'][class~='Mui-checked']
    + [class~='MuiSwitch-track'] {
    /* Visual */
    background-color: $gray-600;
  }
}
