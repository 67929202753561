@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/tools/colorFunctions.module.scss';
@import '../../styles/settings.scss';

$cards-gap: 1rem;

.root {
  /* Box-model */
  display: flex;
  width: $stage-board-list-width;

  /* Flexbox Container */
  flex-direction: column;
}

.addStageButton {
  /* Box-model */
  display: flex;
  width: 100%;

  /* Flex Container */
  justify-content: center;
  align-items: center;

  /* Typography */
  color: $primary-600;

  /* Visual */
  background-color: hex-alpha($primary-600, 0.1);

  border: 1px dashed $primary-600;

  &:hover {
    /* Visual */
    background-color: hex-alpha($primary-600, 0.25);
  }

  &:focus {
    /* Visual */
    background-color: hex-alpha($primary-600, 0.1);
    outline: 4px solid $primary-300;
    outline-offset: 0;
  }

  &:active {
    /* Typography */
    background-color: hex-alpha($primary-600, 0.5);
  }
}

.towerCard {
  /* Box-model */
  margin-top: $cards-gap;

  &:first-of-type {
    /* Box-model */
    margin-top: 1.5rem;
  }
}

.lastEmptyCard {
  /* Box-model */
  margin-top: $cards-gap;
  min-height: $tower-card-height;

  /* Flexbox Item */
  flex: auto;
}
