@import '../../../../assets/styles/settings/colors.module.scss';

.SearchFieldToolbarRoot {
  padding: 2px 14px;
  display: flex;
  align-items: center;
  width: 300px;
  height: 3rem;
}

.SearchFieldToolbarRoot_input {
  margin-left: 8px;
  flex: 1;
}
