.Container {
  background: rgba($color: #000, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  max-height: 70vh;
  box-sizing: border-box;
  background: #fff;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px;
}

.Title {
  font-weight: bold;
  font-size: 23px;
  margin: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  margin: 0 0 25px 0;
  flex-grow: 1;
  color: #6c7293;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex-direction: row-reverse;
}

.ConfirmButton {
  background: #00c781;
  &:disabled {
    background: #aaa;
  }
}

.CancelButton {
  background: #ff4040 !important;
}

.SubtitleRight {
  justify-content: flex-end;
}

.ContainerTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
