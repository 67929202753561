.editableField {
  display: grid;
  grid-template-columns: 80% 20%;
  column-gap: 2%;
  height: 50%;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2%;
}

.textDecorations {
  cursor: pointer;
}
