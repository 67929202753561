@import '../../../assets/styles/settings/colors.module.scss';

.container {
  position: relative;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($primary-600, 0.1);
  z-index: 11000;
}
