@import '../../../../assets/styles/variables.scss';

.IconButton {
  color: $base-color;
  background: $secondary-color;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  padding: 0.5em 0.6em;
  vertical-align: text-top
}