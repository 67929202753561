@import "../../assets/styles/variables.scss";

.Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.AreaContainer {
  display: flex;
  flex-direction: column;
}

.Pie {
  
}
.Aditional {

}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center
}
