@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/components/modal.module.scss';

.modal {
  /* Box-model */
  overflow-y: visible;

  .saveButton {
    /* Positioning */
    position: relative;
  }
}

.title {
  /* Box-model */
  padding-bottom: 8px;
}

.tabs {
  /* Box-model */
  padding: 0 $modal-gutter;

  /* Flexbox Item */
  flex: none;

  /* Visual */
  border-bottom: 1px solid $gray-300;
}

.tabPanel {
  /* Box-model */
  overflow-y: auto;
}

.loadingProgress {
  /* Positioning */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Box-model */
  height: 30px;
}

.circularProgress {
  /* Typography */
  color: $primary-600;
}
