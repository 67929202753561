@import '../../../../assets/styles/settings/colors.module.scss';

.toggleButton {
  border: 2px solid $primary-600;
  border-radius: 8px;
  color: $primary-600;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    background-color: $primary-100 !important;
    border-color: $primary-500;
  }
}

.toggleButtonSelected {
  color: $gray-000 !important;
  background-color: $primary-600 !important;
  &:focus {
    background-color: $primary-600 !important;
    border-color: $primary-600;
  }
  &:active,
  &:hover {
    background-color: $primary-500 !important;
    border-color: $primary-300;
  }
}