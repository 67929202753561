@import '../../../assets/styles/settings/colors.module.scss';
@import '../../../assets/styles/settings/fonts.module.scss';

.root {
  /* Box-model */
  padding: 0.5rem 1rem;

  /* Typography */
  color: $gray-000;
  font-family: $caption-font-family;
  font-size: $caption-font-size;
  font-style: $caption-font-style;
  font-weight: $caption-font-weight;
  letter-spacing: $caption-letter-spacing;
  line-height: $caption-line-height;

  /* Visual */
  background-color: $gray-900;
}

.arrow {
  /* Typography */
  font-size: 1rem;

  /* Visual */
  color: $gray-900;
}
