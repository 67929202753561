@import "../../../../../../assets/styles/settings/colors.module.scss";
@import "../../../../../../assets/styles/settings/fonts.module.scss";

.header {
  height: 42px;
  border-right: solid 1px $gray-300;
  background-color: $primary-100;
}

.header:first-child {
  height: 42px;
  border-top: solid 1px $gray-300;
  background-color: $primary-100;
  border-radius: 4px 0px 0px 0px;
}

.headerTypography{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: $gray-700;
}