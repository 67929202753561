@import '../../../../../assets/styles/settings/colors.module.scss';

.Container {
  position: relative;
}

.TotalBox {
  border: 1px solid $gray-300;
  border-top: 0;
}

.center {
  text-align: center;
}
