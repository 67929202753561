@import '../../../../../assets/styles/settings/colors.module.scss';

.Root {
  width: 78%;
  height: 100%;
  margin-left: 1.563rem;
}

.HeaderContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1.313rem;
  margin-left: 1.75rem;
  margin-right: 1.438rem;
  padding-bottom: 1.313rem;
  border-bottom: 1px solid $gray-300;
}

.TitleContainer {
  margin-bottom: 0.5rem;
}

.LeftContainer {
  margin-top: 0.32rem;
}

.ChargerNumberTitle {
  margin-bottom: 0.25rem;
}

.LeftTypography {
  width: 15.313rem;
  display: inline-block;
}

.RightTypography {
  margin-left: 0.813rem;
}

.RightContainer {
  margin-left: auto;
}

.DownloadPDF {
  margin-right: 1rem;
}
