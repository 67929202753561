@import '../../../../assets/styles/settings/colors.module.scss';

.root {
  padding: 2px 14px;
  display: flex;
  align-items: center;
  width: 300px;
  height: 3rem;
}

.input {
  margin-left: 8px;
  flex: 1;
}

.contractMenu {
  max-width: 400px;
}

.menuItem {
  background-color: $gray-000;
  white-space: inherit;
  &:focus,
  &:hover,
  &:active {
    background-color: $primary-100;
    outline: none;
    > span.MuiListItemText-primary {
      color: $primary-600;
    }
    &.MuiListItemText-secondary {
      color: $primary-100 !important;
    }
  }
}
.menuItemSelected {
  color: $primary-600;
  background-color: $primary-100;
}

.toggleButton {
  border: 2px solid $primary-600;
  border-radius: 8px;
  color: $primary-600;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    background-color: $primary-100 !important;
    border-color: $primary-500;
  }
}

.toggleButtonSelected {
  color: $gray-000 !important;
  background-color: $primary-600 !important;
  &:focus {
    background-color: $primary-600 !important;
    border-color: $primary-600;
  }
  &:active,
  &:hover {
    background-color: $primary-500 !important;
    border-color: $primary-300;
  }
}