@import "../../../assets/styles/settings/colors.module.scss";
@import "../../../assets/styles/tools/colorFunctions.module.scss";

$button-border-radius: 6px;
$button-outline-border-size: 2px;

$button-padding--xsmall: 6px;
$button-ghost-padding--xsmall: 2px;
$icon-size--xsmall: 20px;
$button-padding--small: 8px;
$icon-size--small: 24px;
$button-padding--medium: 8px;
$icon-size--medium: 32px;
$button-padding--large: 4px;
$icon-size--large: 48px;

.buttonFill {
  /* Typography */
  color: $gray-000;

  /* Visual */
  background-color: $primary-600;
  border-radius: $button-border-radius;
  
  &:hover {
    /* Visual */
    background-color: $primary-500;
  }

  &:focus {
    /* Visual */
    outline: none;
  }

  &:active {
    /* Visual */
    background-color: $primary-800;
  }

  &:disabled {
    /* Typography */
    color: $gray-500;

    /* Visual */
    background-color: $gray-300;
  }
}

.buttonOutline {
  /* Typography */
  color: $primary-600;
  
  /* Visual */
  border: $button-outline-border-size solid $primary-600;
  border-radius: $button-border-radius;
  
  &:hover {
    /* Typography */
    color: $primary-500;

    /* Visual */
    border-color: $primary-500;
  }

  &:focus {
    /* Visual */
    outline: $primary-300 solid 2px;
    outline-offset: 0;
  }

  &:active {
    /* Typography */
    color: $primary-800;

    /* Visual */
    border-color: $primary-800;
  }

  &:disabled {
    /* Typography */
    color: $gray-500;

    /* Visual */
    border-color: $gray-500;
  }
}

.buttonGhost {
  /* Typography */
  color: $gray-600;

  /* Visual */
  border-radius: 100%;

  &:hover {
    /* Visual */
    background-color: hex-alpha($gray-600);
  }

  &:focus {
    /* Visual */
    outline: $primary-300 solid 2px;
    outline-offset: 0;
  }

  &:active {
    /* Typography */
    color: $primary-600;
  }

  &:disabled {
    /* Typography */
    color: $gray-400;
  }
}

.buttonXSmall {
  /* Box-model */
  padding: $button-padding--xsmall;

  &.buttonOutline {
    /* Box-model */
    padding: #{$button-padding--xsmall - $button-outline-border-size};
  }

  &.buttonGhost {
    /* Box-model */
    padding: $button-ghost-padding--xsmall;
  }
}

.buttonSmall {
  /* Box-model */
  padding: $button-padding--small;

  &.buttonOutline {
    /* Box-model */
    padding: #{$button-padding--small - $button-outline-border-size};
  }
}

.buttonMedium {
  /* Box-model */
  padding: $button-padding--medium;

  &.buttonOutline {
    /* Box-model */
    padding: #{$button-padding--medium - $button-outline-border-size};
  }
}

.buttonLarge {
  /* Box-model */
  padding: $button-padding--large;

  &.buttonOutline {
    /* Box-model */
    padding: #{$button-padding--large - $button-outline-border-size};
  }
}

.iconXSmall {
  /* Box-model */
  width: $icon-size--xsmall;
  height: $icon-size--xsmall;
}

.iconSmall {
  /* Box-model */
  width: $icon-size--small;
  height: $icon-size--small;
}

.iconMedium {
  /* Box-model */
  width: $icon-size--medium;
  height: $icon-size--medium;
}

.iconLarge {
  /* Box-model */
  width: $icon-size--large;
  height: $icon-size--large;
}
