.container{
  display: flex;
  align-items: center;
  width: 500px;
}

.center{
  margin-left: auto;
  margin-right: auto;
}


.selectContainer{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 18%;
  margin: 3%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
}

.size{
  width: 40%;
}
