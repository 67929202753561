@import '../../../assets/styles/variables.scss';

.Container {
  text-align: center;
}

.Input {
  font-family: inherit;
  text-align: center;
  padding: 11px;
  outline: currentcolor none medium;
  background: transparent;
  color: $main-color;
  font-weight: 600;
  border: none;
  line-height: 24px;
  border-bottom: 1px solid $base-color;
  font-size: 16px;
  transition: border-bottom-color 0.2s ease-in-out;
}

.Input:focus {
  border-bottom: 1px solid $secondary-color;
}

.Input::placeholder {
  color: $defaultPlaceholder-color;

}

.Input:disabled {
  border: none;
}

.ErrorMessage {
  font-size: 14px;
  color: $red-color;
}