.title {
  font-size: 2.5rem;
  text-shadow: 2.5px 2.5px 2.5px gray;
}

.input {
  display: flex;
  text-align: center;
  width: 80px;
  background-color: transparent;
  color: black;
  border: none;
  border-bottom: 2px solid rgb(189, 189, 197);
  border-radius: 10%;
  height: 25px;
}

.input:hover {
  border: none;
  border-bottom: 2px solid #29339b;
  height: 28px;
}

.input:focus {
  outline: 0px;
  border-bottom: 2px solid #29339b;
}

.TableCell {
  color: black;
  text-align: center;
  height: 65px;
  padding: 0px 10px;
  margin: 5%;
  width: 90px;
}

.principal {
  font-size: 1.1rem;
}

.IPCForMinimumSalaryContainer {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 0px;
  padding-top: 10px;
}

.IPCForMinimumSalaryTitle {
  padding-top: 10px;
  padding-right: 25px;
}
