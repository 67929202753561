@import '../../../../../assets/styles/settings/colors.module.scss';

.modal {
  width: 34.875rem;
}

.content {
  padding: 1.75rem 6rem 2.6875rem;
}

.nameField {
  width: 100%;
}

.descriptionField {
  margin-top: 1.375rem;
  width: 100%;
}

.saveButton {
  position: relative;
}

.loadingProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
}

.circularProgress {
  color: $primary-600;
}
