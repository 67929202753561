@import '../../../../assets/styles/settings/colors.module.scss';
@import '../../../../assets/styles/settings/fonts.module.scss';
@import '../../../../assets/styles/tools/colorFunctions.module.scss';
@import '../../../../assets/styles/components/modal.module.scss';

.title {
  /* Box-model */
  padding-top: $modal-gutter;
}

.content {
  /* Box-mdel */
  padding-right: $modal-gutter;
  padding-left: $modal-gutter;

  /* Typography */
  color: $gray-800;
}

.button {
  /* Positioning */
  position: relative;

  /* Box-model */
  padding: 0.65625rem 1.34375rem;
  overflow: hidden;

  /* Typography */
  font-family: $button-small-font-family;
  font-size: $button-small-font-size;
  font-weight: $button-small-font-weight;
  letter-spacing: $button-small-letter-spacing;
  line-height: $button-small-line-height;
  text-transform: capitalize;

  /* Visual */
  border: none;
  cursor: pointer;
  border-radius: 0.375rem;

  &:after {
    /* Positioning */
    position: absolute;
    top: 50%;
    left: 50%;
    
    /* Box-model */
    content: '';
    width: 0;
    height: 0;

    /* Visual */
    background-color: rgba($primary-800, 0.5);
    border-radius: 100%;

    /* Misc */
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    transition: width 0.4s ease, height 0.4s ease;
  }

  &:focus {
    /* Visual */
    outline: 4px solid $primary-300;
    outline-offset: 0;
  }

  &:active {
    &:after {
      /* Box-model */
      width: 300px;
      height: 300px;
    }
  }

  &.primary {
    /* Typography */
    color: $gray-000;

    /* Visual */
    background-color: $primary-600;
    
    &:hover {
      /* Visual */
      background-color: $primary-500;
    }

    &:focus {
      /* Visual */
      background-color: $primary-600;
    }

    &:active {
      /* Visual */
      background-color: $primary-800;
      outline: none;
    }
  }

  &.secondary {
    /* Box-model */
    padding: 0.5625rem 1.25rem;

    /* Typography */
    color: $primary-600;

    /* Visual */
    background-color: transparent;
    border: 0.09375rem solid $primary-600;

    &:hover {
      /* Typography */
      color: $primary-500;

      /* Visual */
      background-color: hex-alpha($primary-500);
      border-color: $primary-500;
    }
    
    &:focus {
      /* Typography */
      color: $primary-600;
  
      /* Visual */
      background-color: transparent;
      border-color: $primary-600;
    }
    
    &:active {
      /* Typography */
      color: $primary-800;
  
      /* Visual */
      background-color: transparent;
      border-color: $primary-800;
      outline: none;
    }
  }

  &.destructive {
    /* Typography */
    color: $gray-000;

    /* Visual */
    background-color: $state-error-600;
    
    &:hover {
      /* Visual */
      background-color: $state-error-500;
    }

    &:focus {
      /* Visual */
      background-color: $state-error-600;
      outline-color: $state-error-300;
    }

    &:active {
      /* Visual */
      background-color: darken($state-error-600, 0.7%);
      outline: none;
    }
  }
}
