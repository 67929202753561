@import '../../../../../assets/styles/variables.scss';

.galeryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButtons {
  display: flex;
  margin-right: 2%;
}

.deleteIcon {
  font-size: 2.2rem;
}

.addIcon {
  font-size: 2.1rem;
}

.imageTypeSelector {
  display: flex;
  justify-content: center;
}

.switch {
  color: $secondary-color;
  width: 400px !important;
  border-radius: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
}
