@import '../../../../assets/styles/variables.scss';

.container {
  display: flex;
  margin: 10px 0;
  & div {
    margin-right: 15px;
  }

  .selected {
    background-color: $main-color;
    border: 2px solid #fff;
    color: #fff;
  }
  .notSelected {
    background-color: #fff;
    border: 2px solid $main-color;
    color: $main-color;
  }
}
