@import '../../../../assets/styles/settings/colors.module.scss';

.main {
  & [class*='MuiStepConnector-root'] {
    height: 2px;
    background: lightgray;
    & [class*='MuiStepConnector-line'] {
      display: inline;
    }

    &[class*='MuiStepConnector'] {
      &-active,
      &-completed {
        background: $primary-600;
      }
    }
  }

}

.stepper > * {
  padding: 0;
}
