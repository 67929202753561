@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/settings/colors.module.scss';

$margin: calc(0px - #{$margin-content});
$padding: $margin-content * 2;

* {
  box-sizing: border-box;
}

.Content {
  margin: $margin $margin 50px $margin;
  padding: 60px $padding 20px $padding;
  background: $gray-000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Button {
  border-radius: 8px;
  border: 2px solid $primary-600;
  padding: 8px 20px 8px 20px;
  text-transform: lowercase;
  & *::first-letter {
    text-transform: uppercase;
  }
}

.CreateButton {
  @extend .Button;
  box-sizing: border-box;
  background-color: $primary-600;
  color: $gray-000;
  &:hover {
    background-color: lighten($primary-700, 10%);
  }
}

.SecondaryButton {
  @extend .Button;
  margin-right: 10px;
  color: $primary-600;
}
