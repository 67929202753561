@import '../../../../../assets/styles/variables.scss';

.dialogContentRoot {
  padding: 0 !important;
}

.fontListItemText {
  font-weight: bold;
}

.quotations {
  color: $main-aqua-blue-color;
}
