@import '../../../../../../assets/styles/settings/colors.module.scss';

.Input {
  border: 1px solid $gray-300;
  color: $gray-500;
  padding: 7px 17px;
  border-radius: 8px;
  margin: 0;
  width: 100%;
  outline: none;
  line-height: 20px;
  font-weight: 400;

  &:hover {
    border-color: $primary-600;
  }

  &:focus {
    border-color: $gray-900;
    outline: none;
    color: $gray-900;
  }
}

.loading {
  color: $gray-300;
}
