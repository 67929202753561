@import '../../../../assets/styles/settings/colors.module.scss';

.InputContainer {
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.InputStaticContainer {
  position: relative;
  align-items: flex-start;
}

.ContentField {
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: $gray-000;
  border: 1px solid $gray-400;
  border-radius: 8px;
}

.ContentStaticField {
  align-items: flex-start;
}

.TextField {
  flex-grow: 1;
  padding: 16px;
  padding-right: 0;
  & textarea::-webkit-scrollbar {
    width: 16px;
  }
  & textarea::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 8px;
    border: 4px solid $gray-300;
  }

  & textarea::-webkit-scrollbar-track {
    background-color: $gray-300;
  }
}

.ErrorTextField {
  border-color: $state-error-600;
}

.Icon {
  width: 48px;
}

.IconSend {
  @extend .Icon;
  margin-left: 8px;
  color: $primary-600;
}
