@import '../../../../../assets/styles/settings/colors.module.scss';

.Footer {
  padding: 20px 40px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  border-radius: 0;
  border: 1px solid $gray-300;
  border-top: 1px solid $gray-200;
}

.Button {
  font-weight: 600;
  font-size: 16px;
  color: $primary-600;
  text-transform: lowercase;
  & *::first-letter {
    text-transform: uppercase;
  }
}
