@import '../../styles/settings.scss';

$board-gutter: calc(#{$stage-view-gutter} + 0.25rem);

.root {
  /* Box-model */
  display: flex;
  padding: 0 0.25rem 0.25rem $board-gutter;
  overflow: auto;

  /* Flexbox Container */
  flex-direction: column;

  /* Flexbox Item */
  flex: auto;
}

.row {
  /* Box-moodel */
  display: flex;
  width: fit-content;
  height: fit-content;

  &:first-child {
    /* Positioning */
    position: sticky;
    top: 0;
    z-index: 2;

    /* Visual */
    background-color: $stage-view-background-color;
  }

  &:last-child {
    /* Positioning */
    z-index: 1;
  }
}

.column {
  /* Flexbox Item */
  flex: 0 0 auto;

  & + .column {
    /* Box-model */
    margin-left: $stage-board-columns-gap;
  }
}

.lastColumnGutter {
  /* Box-model */
  width: $board-gutter;
}
