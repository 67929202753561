@import '../../assets/styles/variables.scss', '../UI/Card/Card.module.scss';

.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 188px;
  margin: 0px 12px;
  flex-grow: 1;

  .AccordionTitle {
    display: flex; 
  }

  .Title {
    margin: 0px 5px;
  }  

  .Card {
    @include Card;
    flex-grow: 1;
  }

  
  .DescriptionItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 5px;
    color: $defaultWhite-color;
  }
  
  .Item {
    display: flex;
    flex-direction: column;
    color: inherit;
    height: 30%;
    background: $defaultWhite-color;
    padding: 3px 5px;
  }
}

.RemoveButton {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    box-shadow: 2px 2px 3px $box-shadow-color;
  }
}

.TowerItem {
  display: flex;
  margin: 5px 0px;

  p {
    margin: 0px 1px;
  }
}

.Line {
  height: 1px;
  background: $gray-color;
  width: 100%;
  flex-direction: row;
}

.TowerContent {
  padding: 10px 0px 0px 30px;
}

.Description {
  display: flex;
  align-content: center;
  justify-content: flex-start;
}
