@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../styles/DeedExpenses.modules.scss';

.DeedExpensesInformation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 $deed-expenses-padding 0 $deed-expenses-padding;
  margin: 0 $deed-expenses-margin 0 $deed-expenses-margin;
  background-color: $gray-200;
}
