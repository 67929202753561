@import '../../../../assets/styles/elements/typography.module.scss';
@import '../../../../assets/styles/settings/colors.module.scss';


.cardHeader {
  @extend .headline6;
  line-height: 1.2 !important;
  letter-spacing: 0.31px !important;
}

.cardSmallContent {
  padding: 2px 6px !important;
}

.textPrimary {
  > td {
    font-weight: 600;
    color: $primary-600 !important;
  }
}