@import "../../../assets/styles/variables.scss";

.Container {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  margin: 10px
}

.Card {
  display: flex;
  max-width: 260px;
  min-width: 225px;
  width: 25%;
  background: #eef0f2;
  flex-direction: row;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}


.Icon {
  display: grid;
  background: #4ba3c3;
  align-content: center;
  font-size: 100%;
  padding: 0 24px;
  flex-direction: row;
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.Label {
  color: #4ba3c3;
  margin: auto;
  padding: 12% 12% 0 12%;
  display: inline-block;
  white-space: nowrap;
}

.P {
  padding-left: 12%;
  padding-right: 12%;
}

