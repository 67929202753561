@import '../../../assets/styles/settings/colors.module.scss';

$box-shadow: 0px 1px 1px -1px #6985a1, 0px 4px 12px rgba(161, 171, 181, 0.25);

.root {
  & [class~='MuiMenu-paper'] {
    /* Visual */
    box-shadow: $box-shadow;
    border-radius: 6px;

    &:focus {
      /* Visual */
      outline: none;
    }
  }
}

.arrow {
  & [class~='MuiMenu-paper'] {
    /* Box-model */
    margin-top: 0.5rem;
    overflow: initial;

    /* Add nice pointer :D */
    &::before {
      /* Positioning */
      position: absolute;
      top: -0.25rem;
      right: 0.25rem;
      z-index: -1;
      transform: rotate(45deg);

      /* Box-model */
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;

      /* Visual */
      opacity: 0;
      background-color: $gray-000;
      box-shadow: $box-shadow;

      /* Misc */
      transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &.onEntered {
      &::before {
        /* Visual */
        opacity: 1;
      }
    }

    &.onExit {
      &::before {
        /* Box-model */
        display: none;

        /* Misc */
        transition: none;
      }
    }
  }

  & [class~='MuiList-root'] {
    /* Box-model */
    overflow-x: hidden;
    overflow-y: auto;
  }
}
