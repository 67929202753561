@import '../../../../../assets/styles/settings/colors.module.scss';
@import '../../../../../assets/styles/tools/colorFunctions.module.scss';
@import '../../styles/settings.scss';

@mixin Divider {
  /* Box-model */
  height: 0.125rem;

  /* Visual */
  border: 0.0625rem solid $gray-400;

  /* Flexbox Item */
  flex: auto;
}

.root {
  /* Positioning */
  position: relative;

  /* Box-model */
  display: flex;
  padding: 0.5rem 0;
  width: $stage-board-list-width;

  /* Flexbox Container */
  justify-content: center;

  &:first-child .divider:first-child {
    /* Visual */
    border-color: transparent;
  }

  &:last-child .divider:last-child {
    /* Visual */
    border-color: transparent;
  }

  & + &::before {
    @include Divider();

    /* Positioning */
    position: absolute;
    top: 50%;
    left: -#{$stage-board-columns-gap};
    transform: translateY(-50%);

    /* Box-model */
    content: '';
    width: $stage-board-columns-gap;
  }
}

.divider {
  @include Divider();

  /* Positioning */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.header {
  /* Box-model */
  display: flex;
  padding: 0 1rem;
  width: fit-content;

  /* Flexbox Container */
  align-items: center;
}

h2.titleHeading {
  /* Box-model */
  margin: 0;
  padding-top: 0.125rem;
}

.deleteButton {
  /* Box-model */
  margin-left: 0.5rem;
}
