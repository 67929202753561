.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto auto auto 1fr 1fr;

  & * {
    text-align: center;
    padding: 10px;
  }

  & > div {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  .header {
    font-weight: 700;
  }

  .combinedHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .subHeader {
    font-weight: 700;
  }

  .combinedRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .subRow {
    display: block;
  }
}
