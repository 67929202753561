@import "../../../assets/styles/settings/colors.module.scss";
@import "../../../assets/styles/settings/fonts.module.scss";

.root {
  /* Box-model */
  min-height: auto;
  min-width: auto;

  /* Typography */
  color: $body1-color;
  font-family: $body1-font-family;
  font-size: $body1-font-size;
  font-style: $body1-font-style;
  font-weight: $body1-font-weight;
  letter-spacing: $body1-letter-spacing;
  line-height: $body1-line-height;
  text-transform: capitalize;

  /* Visual */
  opacity: 1;

  &:hover {
    /* Typography */
    color: $primary-600;
  }

  &:focus {
    /* Visual */
    outline: none;
  }

  &.Mui-selected {
    /* Typography */
    color: $primary-600;
  }
}

.small {
  /* Box-model */
  padding: 10px 16px;
}

.medium {
  /* Box-model */
  padding: 14px 16px;
}

.large {
  /* Box-model */
  padding: 18px 16px;
}