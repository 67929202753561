@import '../../assets/styles/variables.scss';

.collapseAndExpandRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  background-color: $main-color;
  border-radius: 50% !important;
  color: $defaultWhite-color;
  height: 30px !important;
  margin-top: 15px;
  position: absolute;
  text-align: center;
  width: 30px !important;
  z-index: 998;
  top: 100%;
  left: 0px;
  &:hover {
    cursor: pointer;
  }
}
.container {
  position: relative;
  left: -15px;
}

.arrowPosition {
  font-size: 10px;
}
