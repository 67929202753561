.NewClientDialogContainer {
  border-radius: 10px;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

.clientInformationContainer {
  display: flex;
  margin-bottom: 10px;
}

.infoTypeSelector {
  display: flex;
  justify-content: center;
}

.switch {
  width: 80% !important;
  border-radius: 10px;
  color: #00a3a9;
}

.indicator {
  display: flex;
  width: 50%;
  align-items: center;
  line-height: 17px;
}

.customMarginForButton {
  margin: 0px;
  padding: 0px;
  height: 40px;
  width: 50%;
}

.customWidthForInput {
  width: 100%;
}

.input {
  height: 40px;
  width: 50%;
  margin-bottom: 12px !important;
}

.civilStatusSelector {
  width: 50%;
}

.dialogActions {
  padding: 3% 6%;
}
