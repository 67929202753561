@import '../../../assets/styles/settings/colors.module.scss';


.DialogContent {
  padding: 0;
}

.BoxTitle {
  background-color: $primary-100;
  color: $primary-800;
  border-radius: 4px;
}

.CloseIcon {
  position: absolute;
  right: 1rem;
}

.outlineNone {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}