@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Source+Sans+Pro&display=swap');

.primary{
  box-sizing: border-box;
  height: 40px;
  min-width: 160px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  background: linear-gradient(155.94deg, #00D0D4 0%, #00A3A9 100%);
  font-size: 16px;
  color: #FFFFFF;
  font-family: 'quicksand';
  font-weight: 600;
}

.secondary{
  box-sizing: border-box;
  height: 40px;
  min-width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
  font-size: 16px;
  color: #00D0D4 !important;
  font-family: 'quicksand';
  font-weight: 600;
  margin-right: 10px;
}

.selectedSecondary{
  box-sizing: border-box;
  height: 40px;
  min-width: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #E5F5F6;
  font-size: 16px;
  color: #00D0D4;
  font-family: 'quicksand';
  font-weight: 600;
  margin-right: 10px;
}