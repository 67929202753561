@import '../../../assets/styles/settings/colors.module.scss';

$main-gutter: 4.5rem;
$toolbar-padding-bottom: 1rem;

.root {
  /* Box-model */
  display: flex;
  height: 100%;

  /* Flexbox Container */
  flex-direction: column;
}

.toolbar {
  /* Positioning */
  position: relative;

  /* Box-model */
  display: flex;
  padding: 0 $main-gutter $toolbar-padding-bottom;
  height: 5.625rem;

  /* Flexbox Container */
  justify-content: center;
  align-items: flex-end;

  /* Flexbox Item */
  flex: none;

  &::after {
    /* Positioning */
    position: absolute;
    bottom: 0;
    left: $main-gutter;

    /* Box-model */
    content: '';
    width: calc(100% - #{2 * $main-gutter});
    height: 0.0625rem;

    /* Visual */
    background-color: $gray-300;
  }
}

.toolbarTitle {
  /* Positioning */
  position: absolute;
  bottom: $toolbar-padding-bottom;
  left: $main-gutter;

  /* Box-model */
  margin: 0;
}

.content {
  /* Box-model */
  overflow-y: auto;

  /* Flexbox Item */
  flex: auto;
}

.loader {
  /* Box-model */
  margin-top: 1.5rem;

  /* Typography */
  text-align: center;
}
