@import "../../../../../assets/styles/settings/colors.module.scss";

.TotalCashFlowTabOption {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 40px;
  min-height: 40px;
  color: $gray-700;
  cursor: pointer;
  padding: 0 16px 0 16px;
  font-weight: 400;
  font-size: 16px;
}

.Selected {
  color: $primary-600;
  box-shadow: inset 0 -2px $primary-600;
}
