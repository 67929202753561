@import "../../../assets/styles/variables.scss";

.float {
  position: fixed;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: $main-aqua-blue-dark-color;
  box-shadow: 2px 2px 3px $box-shadow-color;
  animation: slideDown 1s ease;
  z-index: 100;
}

@keyframes slideDown {
  0% {
    transform: translateX(200%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
