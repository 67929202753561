@import "../../../../assets/styles/settings/colors.module.scss";
@import "../../../../assets/styles/settings/fonts.module.scss";

.virtualTableCell {
  padding: 0%;
  height: 42px;
  width: 126px;
  border-bottom: 1px solid #e7e7ea;
  font-size: 16px;
}

.virtualTableCell:last-child {
  border-right: solid 1px $gray-300;
}

.groupsFloor {
  display: flex;
  align-items: center;
  padding: 0%;
  width: 100%;
  height: 100%;
  font-size: 16px !important;
  background-color: $gray-000 !important;
}

.groupTypography {
  color: $gray-800;
  font-weight: 400;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
  margin-left: 10px;
}

.utilityFloor {
  display: flex;
  align-items: center;
  padding: 0%;
  width: 100%;
  height: 100%;
  background-color: $success-200 !important;
  font-size: 16px;
}

.utilityTypography {
  color: $gray-900 !important;
  font-style: normal;
  font-family: $subtitle1-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 100%;
}

.typographySize {
  font-size: 16px;
  margin-left: 10px;
}

.withoutQuote {
  color: red;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
}

.itemsFloor {
  display: flex;
  align-items: center;
  padding: 0%;
  width: 100%;
  height: 100%;
  background-color: $gray-90 !important;
  font-size: 16px;
}

.itemsTypography {
  color: $gray-700;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
  margin-left: 10px;
}

.contractsFloor {
  display: flex;
  align-items: center;
  padding: 0%;
  width: 100%;
  height: 100%;
  background-color: $gray-80;
  font-size: 16px;
}

.contractsTypography {
  color: $gray-700;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
}

.text {
  height: 50px;
  left: 15.24%;
  right: 40%;
  top: calc(50% - 50px / 2 + 8px);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $primary-900;
  width: 210px;
  height: 123px;
  background-color: $gray-000;
  border: 3px solid $gray-200;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.rightLine {
  border-right: solid 1px $primary-600;
}

.leftLine {
  border-left: solid 1px $primary-600;
}

.utilityLine {
  border-bottom: solid 1px $primary-600;
}

.errorRightLine {
  border-right: solid 1px $state-error-700;
}

.errorLeftLine {
  border-left: solid 1px $state-error-700;
}

.errorUtilityLine {
  border-bottom: solid 1px $state-error-700;
}

.editableCell {
  margin-left: 5px;
}
