@import '../../../../../assets/styles/settings/colors.module.scss';

.TextField {
  max-height: 40px;
  width: 150px;

  & input {
    min-height: 40px;
    padding: 0px 14px;
  }

  & label {
    transform: translate(14px, 12px) scale(1);
  }
}

.Disabled {
  background-color: $gray-200;
  color: $gray-600;
}
