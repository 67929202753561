@import '../../../../assets/styles/settings/colors.module.scss';

.ContractLotFormMainView_content {
  padding: 0;
}

.ContractLotFormMainView_boxTitle {
  background-color: $primary-100;
  color: $primary-800;
  border-radius: 4px;
}

.ContractLotFormMainView_closeIcon {
  position: absolute;
  right: 1rem;
}

.TextFieldDisabled {
  background-color: $gray-200;
}
