// @import '../../assets/styles/variables.scss';

.container {
  display: flex;
  text-align: center;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.card {
  width: 320px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 20px;
  }

  .input {
    width: 100%;
    margin-bottom: 20px;
  }

  .submit {
    margin-bottom: 10px;
  }

  .link {
    text-decoration: none;
  }
}